import { defineStore } from 'pinia';
import axios from 'axios';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: {},
    isAuthenticated: false
  }),
  actions: {
    async login(code) {
      if (code) {
        console.log("requesting jwt " + code)
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/callback?code=${code}`);
        const { access_token } = response.data;
        localStorage.setItem('token', access_token);
      }
      const token = localStorage.getItem('token');
      //console.log("getting user info " + token);
      const userResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/user/me`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      this.user = userResponse.data;
      this.isAuthenticated = true;
    },
    async impersonate(userid) {
      if (userid) {
        const response = await axios.put(`${process.env.VUE_APP_API_BASE_URL}/bypass?user_id=${userid}`, {});
        const { access_token } = response.data;
        localStorage.setItem('token', access_token);
      }
      const token = localStorage.getItem('token');
      console.log("getting user info " + token);
      const userResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/user/me`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      this.user = userResponse.data;
      this.isAuthenticated = true;
      
    },
    logout() {
      this.user = {};
      this.isAuthenticated = false;
      localStorage.removeItem("token");
    },
  },
});