<template>
  <div>
    <h1 class = "mt-4">Join League</h1>
    <form>
      <div class="container px-4 text-center">
        <div class="row gx-5 mb-3">
          <div class="col">
      <label for="inputPassword" class="form-label">League Password</label>
      <input v-model="code" class="form-control" id="inputPassword" aria-describedby="emailHelp">
    </div>
        </div>
        <div class="row gx-5 mb-3">
          <div class="col">
    <label for="exampleInputPassword1" class="form-label">Nickname</label>
    <input v-model="nickname" class="form-control" id="exampleInputPassword1">
  </div>
        </div>
        <div class="row gx-5">
          <div class="col">
  <button @click.prevent="join" class="btn btn-success">Join</button>
          </div>
          </div>
      </div>
</form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useAuthStore } from '@/stores/authentication';
import { useLeagueStore } from '@/stores/league';
const authStore = useAuthStore()
const router = useRouter()
const leagueStore = useLeagueStore();

const user = ref(authStore.user)
const code = ref("");
const nickname = ref("");

const join = async () => {
  //console.log("click - " + code + " - " + nickname);
  //console.log("router: " + router);
  await leagueStore.join(code.value, nickname.value, user.value.strava_id);
  router.push({ name: 'LeagueHome' });
};
//const userRoles = ["user", "super", "admin"]

onMounted(async () => {
  const leagueid = new URLSearchParams(window.location.search).get('leagueId');
  if(leagueid) {
    const nickname = new URLSearchParams(window.location.search).get('nickname');
    const token = localStorage.getItem('token');
    const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/league/id/${leagueid}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    await leagueStore.join(leagueResponse.data.password, nickname, user.value.strava_id);
    router.push({ name: 'LeagueHome' });
  }
});
</script>

<style scoped>
  </style>