<template>
  <div v-if ="stageNameMaps">
  <table class="table mt-4">
    <thead>
    <tr>
        <th scope="col">#</th>
        <th scope="col">Username</th>
        <th scope="col">Points</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(player,index) in sortedPlayers" :key="index">
      <td>{{ index + 1}}</td>
      <td><button type="button" class="btn btn-link p-0 m-0" data-bs-toggle="modal" :data-bs-target="'#'+player.player_strava_id">{{ player.player_nickname }}</button></td>
        <div class="modal fade" :id="player.player_strava_id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">{{player.player_nickname}}'s Roster</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" @click="closeModal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="d-flex justify-content-center mt-3">
                  <table>
                    <tr>
                      <td>
                        <div>
                          <ul class="list-group">
                            <li
                              v-for="racer in player.active_racers"
                              :key="racer.id"
                              class="list-group-item d-flex justify-content-between align-items-center"
                            >
                              <div class="d-flex align-items-center">
                                <div class="image-parent">
                                  <img src="blank-profile-picture.png" class="img-fluid" alt="Racer Image">
                                </div>
                                <div class="content-wrapper">
                                  <a :href="racer.url" target="prosite"><strong>{{ racer.name }}</strong></a>
                                  <p v-if="racer.specialties.length > 0"> {{ racer.specialties.join(', ') }}</p>
                                  <p v-else> None</p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <ul class="list-group">
                            <li
                              v-for="racer in player.bench_racers"
                              :key="racer.id"
                              class="list-group-item list-group-item-secondary d-flex justify-content-between align-items-center"
                            >
                              <div class="d-flex align-items-center">
                                <div class="image-parent">
                                  <img src="blank-profile-picture.png" class="img-fluid" alt="Racer Image">
                                </div>
                                <div class="content-wrapper">
                                  <a :href="racer.url" target="prosite"><strong>{{ racer.name }}</strong></a>
                                  <p v-if="racer.specialties.length > 0">{{ racer.specialties.join(', ') }}</p>
                                  <p v-else> None</p>
                                </div>
                              </div>
                              <div class="btn-group ms-2" role="group" aria-label="Basic example">
                              </div>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      <td><button type="button" class="btn btn-link p-0 m-0" data-bs-toggle="modal" :data-bs-target="'#'+player.id">{{ player.current_score }}</button></td>
        <div class="modal fade" :id="player.id" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel2">Scores for {{ player.player_nickname }}</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div v-for="stage in player.stage_scores" :key="stage">
                  <h2><a v-bind:href="stage.stage_url" target="prosite">{{stageNameMaps.get(stage.stage_url)[0].name}}</a></h2>
                  <table class="table">
                    <thead>
                          <tr>
                            <th scope="col">Racer</th>
                            <th scope="col">Points Earned</th>
                            <th scope="col">How Earned</th>
                          </tr>
                        </thead>
                    <tbody>
                      <tr v-for="(racer) in stage.racers" :key="racer.url">
                        <td><b>{{ racer.name }}</b>
                          <span v-if="scoresMap.get(stage.stage_url)">
                            <span v-for="s in scoresMap.get(stage.stage_url).get(racer.url)" :key="s">
                              <span v-if="s.category === 'white'">
                                <img src="/white.png" alt="White Jersey" style="width: 20px; height: 20px;" />
                              </span>
                              <span v-if="s.category === 'yellow'">
                                <img src="/yellow.png" alt="Yellow Jersey" style="width: 20px; height: 20px;" />
                              </span>
                              <span v-if="s.category === 'green'">
                                <img src="/green.png" alt="Green Jersey" style="width: 20px; height: 20px;" />
                              </span>
                              <span v-if="s.category === 'polkadot'">
                                <img src="/polkadot.png" alt="Polka-dot Jersey" style="width: 20px; height: 20px;" />
                              </span>
                            </span>
                          </span>
                        </td>
                        <td>
                          <div v-if="scoresMap.get(stage.stage_url)">
                            <div v-for="s in scoresMap.get(stage.stage_url).get(racer.url)" :key="s">
                              <span v-if="!['white', 'yellow', 'green', 'polkadot'].includes(s.category)">{{ s.point }}</span>
                              <span v-else-if="s.point !== 0">{{ s.point }}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div v-if="scoresMap.get(stage.stage_url)">
                            <div v-for="s in scoresMap.get(stage.stage_url).get(racer.url)" :key="s">
                              <span v-if="!['white', 'yellow', 'green', 'polkadot'].includes(s.category)">{{ isNaN(s.category)?s.category:s.category+(s.category%10==1&&s.category%100!=11?'st':s.category%10==2&&s.category%100!=12?'nd':s.category%10==3&&s.category%100!=13?'rd':'th') }}</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="border-bottom border-2">
                            <th scope="row">Total</th>
                            <td>{{ stage.total_score }}</td>
                            <td></td>
                          </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
            </div>
          </div>
    </tr>
    </tbody>
  </table>
</div>
</template>

<script setup>
import { computed, ref, onMounted, defineProps } from 'vue';
import axios from 'axios';

const players = ref([]);
const league = ref({});

const sortedPlayers = computed(() => {

  let copy = players.value.slice();
  copy.sort((a,b) => {return b.current_score - a.current_score});

  //console.log(JSON.stringify(copy));
  return copy//leagueStore.players//.slice().sort((a,b) => a.current_score - b.current_score);
});

const loadLeague = async ()=> {
  const token = localStorage.getItem('token');
      const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/league/id/${props.leagueId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      league.value = leagueResponse.data;
};

const getLeaguePlayers = async () => {
      const token = localStorage.getItem('token');
      const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/league/${props.leagueId}/players`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      players.value = leagueResponse.data;
    };

const stageScores = ref([]);
const scoresMap = ref(new Map());
const loadStageScores = async () => {

  const token = localStorage.getItem('token');
  const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/racerScore/search?race=${league.value.active_race_url}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  stageScores.value = leagueResponse.data;
  //console.log(stageScores.value);

  let map = Map.groupBy(stageScores.value, ({stage}) => stage);
  map.forEach( (v, stageurl, m) => {
    m.set(stageurl, Map.groupBy(v, ({racer}) => racer));
  })

  scoresMap.value = map;
  //console.log(map);
};

const stageNameMaps = ref(null);
const loadStageNameMaps = async() => {
  const token = localStorage.getItem('token');
  const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/race/url?url=${league.value.active_race_url}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  console.log(leagueResponse.data);
  stageNameMaps.value = Map.groupBy(leagueResponse.data.days, ({url}) => url);
};

onMounted( async () => {
  //getPlayers(league.value.id);
  console.log("Mounted");
  
  await loadLeague();
  await getLeaguePlayers();
  await loadStageScores();
  await loadStageNameMaps();
});

const props = defineProps({
  leagueId: {
    type: String,
    required: true
  },
});


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.image-parent {
  width: 50px; /* Adjust width of the image container */
  margin-right: 10px; /* Adjust margin between image and content */
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.content-wrapper {text-align: left;
}

.btn-link.p-0.m-0 {
  color: #198754; /* Bootstrap success color */
}

.btn-link.p-0.m-0:hover,
.btn-link.p-0.m-0:focus,
.btn-link.p-0.m-0:active {
  color: #157347; /* Darker shade for hover, focus, active states */
}
</style>