<template>
  <div>
    <h1 class = "mt-4">League Maintenance</h1>
      <table class="mx-auto" style="width: 400px;">
        <tr><td>Id</td><td>{{league.id}}</td></tr>
        <tr><td>Name</td><td><input v-model="league.name" /> </td></tr>
        <tr><td>Password</td><td><input v-model="league.password" /></td></tr>
        <tr><td>Active Race</td><td><a v-bind:href="league.active_race_url" target="prosite">{{ league.activeRace }}</a></td></tr>
      </table>
      <button @click="updateLeague">Update League</button>
    <h1 class = "mt-4">League Players</h1>
      <button @click="updatePlayers">Update Players</button>
      <table class="table mt-4">
        <thead>
          <tr>
            <th v-for="(player) in players" :key="player.player_strava_id"> {{ player.player_nickname }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(player) in players" :key="player">
              <input v-for="(item, index) in player.active_racers" :key="index" v-model="player.active_racers[index].url " size="50" />
            </td>
          </tr>
          <tr>
            <td v-for="(player) in players" :key="player">
              <input v-for="(item, index) in player.bench_racers" :key="index" v-model="player.bench_racers[index].url " size="50" />
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</template>

<script setup>
import axios from 'axios';
import { computed, ref, onMounted } from 'vue';
import { useLeagueStore } from '@/stores/league';
const leagueStore = useLeagueStore()

const league = ref(leagueStore.league);
const players = computed(() => {
  return leagueStore.players.slice();
});

const getPlayers = leagueStore.getLeaguePlayers;

const updateLeague = () => {
  console.log(league.value);

  axios.put(`${process.env.VUE_APP_API_BASE_URL}/league/update-league/${league.value.id}`, league.value)
    .then(function (response) {
      // handle success
      console.log(response);
  });
}

const updatePlayers = async () => {
  const token = localStorage.getItem('token');
  const racerResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/racer/race?race_url=${league.value.active_race_url}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  const map = new Map(racerResponse.data.map((obj) => [obj.url, obj]));

  players.value.forEach(p => {
    const activeRacers = [];
    p.active_racers.forEach( r => {
      activeRacers.push(map.get(r.url))
    })
    const benchRacers = [];
    p.bench_racers.forEach ( r => {
      benchRacers.push(map.get(r.url))
    })
    p.activeRacers = activeRacers;
    p.active_racers = activeRacers;
    p.benchRacers = benchRacers;
    p.bench_racers = benchRacers;

    const playerResponse = axios.put(`${process.env.VUE_APP_API_BASE_URL}/league/leagueplayers`, p, {
    headers: {
      'Authorization': `Bearer ${token}`
    }    
    });
    console.log(playerResponse);
  });

}


onMounted(() => {
  getPlayers(league.value.id);
  leagueStore.getRace();
});


</script>

<style scoped>
</style>