<template>
  <h2>Teams</h2>
  <button @click="initializeDraft()" :disabled="initialized"> Initialize Live Draft </button>
  <button @click="submitDraft()" :disabled="turnPosition != -1" > Submit Draft Results</button>
  <table>
    <tbody><tr>
      <td v-for="p, i in leagueStore.players" :key="p" :class="{ 'border-success-thick': i === turnPosition }">
        <div>{{ p.player_nickname }}</div>
        <input type="checkbox" :id="p.id" :value="p.player_strava_id" v-model="autopilots" @change="autoSelect()"/><label :for="p.id">auto</label>
        <div v-for="r in teamsMap.get(p.player_strava_id)" :key="r"> {{ r.name }}</div>
      </td>
    </tr></tbody>
  </table>

  <h2>Available Racers</h2>
  <ul>
    <li v-for="r in availableRacers" :key="r"> {{ r.name }}</li>
  </ul>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import axios from 'axios';
import EventSourceStream from '@server-sent-stream/web';

import { useRacerStore } from '@/stores/racer';
import { useLeagueStore} from '@/stores/league';

const leagueStore = useLeagueStore();

const racerStore = useRacerStore();
const availableRacers = computed(() => {
  return racerStore.allRacers.filter( r => !r.player)
});
const racerMap = computed (()=> {
  return new Map(racerStore.allRacers.slice().map( r=> [r.url, r]));
});

const teamsMap = computed (() => {
  return Map.groupBy(racerStore.allRacers.slice(), ({player}) => player);
});

/*
const playerMap = computed ( () => {
  return new Map(leagueStore.players.slice().map( p => [p.player_strava_id, p]));
});
*/
const nextSeq = ref(0);

const turnPosition = computed( () => {
  const next = nextSeq.value;
  const length = leagueStore.league.players.length;
  if (next == length*12) return -1;
  return Math.floor(next/length)%2==0?next%length:length-(next%length)-1;
})

const initialized = ref(false);

const initializeDraft = async () => {
  console.log(racerStore.allRacers.length + " " + availableRacers.value.length)
  if (racerStore.allRacers.length == availableRacers.value.length) {
    leagueStore.shuffle();
    leagueStore.players.forEach( p => {
      p.active_racers = [];
      p.bench_racers = [];
    });
    const token = localStorage.getItem('token');
    await axios.put(`${process.env.VUE_APP_API_BASE_URL}/league/draftresult`, leagueStore.players, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    //leagueStore.loadLeague(leagueStore.league.id);
    //leagueStore.getLeaguePlayers(leagueStore.league.id);
    leagueStore.league.players = leagueStore.players.map(({player_strava_id}) => player_strava_id);
  }

  initialized.value= true;
};

const autopilots = ref([]);

const autoSelect = async () => {
  console.log(autopilots.value)
  console.log(leagueStore.league.players[turnPosition.value])
  if(autopilots.value.includes(leagueStore.league.players[turnPosition.value])) {
    await axios.post(`${process.env.VUE_APP_API_BASE_URL}/livedraft/draftracer`, {
      league_id: leagueStore.league.id,
      player_id: leagueStore.league.players[turnPosition.value],
      racer_url: availableRacers.value[0].url,
      seq: nextSeq.value
    })
      .then(function (response) {
        // handle success
        console.log(response);
      })
  }
};

const submitDraft = async () => {
  leagueStore.players.forEach( p => {
    const drafted = teamsMap.value.get(p.player_strava_id);
    p.active_racers = drafted.slice(0,8);
    p.bench_racers = drafted.slice(8);
  });
  leagueStore.submitDraft();
  leagueStore.league.players = leagueStore.players.map(({player_strava_id}) => player_strava_id);
  controller.abort();
};

const controller = new AbortController();

const getStreamAxios = () => {
  const token = localStorage.getItem('token');
  axios.get(`${process.env.VUE_APP_API_BASE_URL}/livedraft/stream/${leagueStore.league.id}`, {
    headers: {
      'Accept': 'text/event-stream',
      'Authorization': `Bearer ${token}`
    },
    responseType: 'stream',
    adapter: 'fetch', // <- this option can also be set in axios.create()
    signal: controller.signal
  })
    .then(async (response) => {
      console.log('axios got a response');
      const stream = response.data;      

      // consume response
      const reader = stream.pipeThrough(new EventSourceStream()).getReader();
      for (;;) {
        const { value, done } = await reader.read();
        if (done) break;
        //data.value = data.value + value.data;
        //console.log(value)
        const msg = JSON.parse(value.data);
        //console.log(msg);
        if(value.type === 'draft_position') {
          //myTurns.value = msg
        } else {
          nextSeq.value = msg.slice(-1)[0].seq+1
          msg.slice().forEach( s => {
            racerMap.value.get(s.racer_url).player = s.player_id
          });
          if (msg.length > 0) initialized.value = true;
          autoSelect();
          //if (selectedRacer.value && racerMap.value.get(selectedRacer.value.url).player) selectedRacer.value = null;
        }
      }
    }).catch(
    function (error) {
      console.log(error)
      //return Promise.reject(error)
    }
  )
}

onMounted(() => {
  racerStore.getRacerlist(leagueStore.league.active_race_url);
  getStreamAxios();
});

onUnmounted( () => {
  controller.abort()
});
</script>

<style scoped>
.border-success-thick {
  border: 4px solid #28a745; /* Bootstrap success green with thicker border */
}
</style>