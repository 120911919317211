<template>
  <div>
    <h1 class="mt-4">Full Scores</h1> <span>* available racers</span>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Racer</th>
          <th scope="col">Total</th>
          <th v-for="s in stageList" :key="s" scope="col">{{ s.shortname }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(racer) in totalScoreList" :key="racer">
          <td style="vertical-align: middle"><b>{{ racerNameMap.get(racer.url)[0].name }} {{ racerNameMap.get(racer.url)[0].player ? "" : "*" }}</b></td>
          <td style="vertical-align: middle"><b>{{ racer.point }} </b></td>
          <td v-for="(stage, stageIndex) in stageList" :key="stageIndex">
            <div v-if="scoresMap.get(racer.url).get(stage.url)">
              <div>
                <span v-for="s in scoresMap.get(racer.url).get(stage.url)" :key="s">
                  <img v-if="['white', 'yellow', 'polkadot', 'green', '1', '2', '3', 'youth#1', 'youth#2', 'youth#3', 'gc#1', 'gc#2', 'gc#3'].includes(s.category)" 
                       :src="'/' + s.category.replace('#', '_') + '.png'" style="width: 20px; height: 20px;" />
                </span>
                <img src="/blank.png" style="width: 1px; height: 20px;" />
              </div>
              <div v-if="scoresMap.get(racer.url).get(stage.url).reduce((a, n) => a + n.point, 0) != 0">
                <a href="#" data-bs-toggle="collapse" :data-bs-target="'#collapse' + racer.url + stage.url">
                  {{ scoresMap.get(racer.url).get(stage.url).reduce((a, n) => a + n.point, 0) }}
                </a>
              </div>
              <div :id="'collapse' + racer.url + stage.url" class="collapse">
                <div>
                  <span v-for="(s, scoreIndex) in scoresMap.get(racer.url).get(stage.url)" :key="scoreIndex">
                    <div v-if="!['white', 'yellow', 'polkadot', 'green'].includes(s.category)">
                      <div v-if= "s.category.includes('#')">
                        <span>
                          {{ s.point }} - {{ s.category.split('#')[0] }} {{ isNaN(s.category.split('#')[1]) ? s.category.split('#')[1] : s.category.split('#')[1] + (s.category.split('#')[1] % 10 == 1 && s.category.split('#')[1] % 100 != 11 ? 'st' : s.category.split('#')[1] % 10 == 2 && s.category.split('#')[1] % 100 != 12 ? 'nd' : s.category.split('#')[1] % 10 == 3 && s.category.split('#')[1] % 100 != 13 ? 'rd' : 'th') }}
                        </span>
                      </div>
                      <div v-else>
                      <span>
                        {{ s.point }} - {{ isNaN(s.category) ? s.category : s.category + (s.category % 10 == 1 && s.category % 100 != 11 ? 'st' : s.category % 10 == 2 && s.category % 100 != 12 ? 'nd' : s.category % 10 == 3 && s.category % 100 != 13 ? 'rd' : 'th') }}
                      </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useLeagueStore } from '@/stores/league';
import { useRacerStore } from '@/stores/racer';
import axios from 'axios';

const leagueStore = useLeagueStore()

const league = ref(leagueStore.league);

const stageScores = ref([]);
const scoresMap = ref(new Map());
const totalScoreList = ref([]);
const loadStageScores = async () => {

  const token = localStorage.getItem('token');
  const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/racerScore/search?race=${league.value.active_race_url}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  stageScores.value = leagueResponse.data;
  //console.log(stageScores.value);

  let map = Map.groupBy(stageScores.value, ({racer}) => racer);

  let tmap = new Map();
  map.forEach( (v, k) => {
    tmap.set(k, v.reduce((a, n) => a+n.point, 0));
  } );
  let tslist = Array.from(tmap, (p) => {return {url: p[0], point: p[1]}});
  tslist.sort((a,b) => b.point-a.point);
  totalScoreList.value = tslist;

  map.forEach( (v, stageurl, m) => {
    let list = Map.groupBy(v, ({stage}) => stage);
    //list.sort( (a,b) => a.stage.localeCompare(b.stage))
    m.set(stageurl, list);
  })

  scoresMap.value = map;
  //console.log(scoresMap);
};


const stageList = computed(() => {
  let l = leagueStore.race.days.slice().filter(d => d.name != 'Restday');
  l.sort((a,b) => a.seq - b.seq);
  l.forEach(d => {
    d.shortname = d.url.substring(d.url.lastIndexOf('-')+1);
  });
  return l;
});

const racerStore = useRacerStore();
const racerNameMap = computed( () => {
  let namemap = Map.groupBy(racerStore.allRacers, ({url}) => url);
  return namemap;
});

const getPlayers = leagueStore.getLeaguePlayers;
onMounted(async () => {
  await racerStore.getRacerlist(league.value.active_race_url);
  await getPlayers(league.value.id);
  await leagueStore.getRace();
  await loadStageScores();
});


</script>