<template>
  <div>
    <h1>By Pass</h1>
    <form>
      <div class="container px-4 text-center">
        <div class="row gx-5 mb-3">
          <div class="col">
          <label for="inputPassword" class="form-label">User Id</label>
          <input v-model="userid" class="form-control" id="inputPassword" aria-describedby="emailHelp">
          </div>
        </div>
      </div>
      <div class="row gx-5">
        <div class="col">
          <button @click.prevent="join" class="btn btn-primary">Impersonate</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authentication';
import { useLeagueStore } from '@/stores/league';
const authStore = useAuthStore()
const leagueStore = useLeagueStore()
const router = useRouter()
const userid = ref("");

const join = async () => {

  await authStore.impersonate(userid.value);
  if (authStore.user.current_league_id) {
    await leagueStore.loadLeague(authStore.user.current_league_id);
    router.push({ name: 'LeagueHome'});
  } else {
    router.push({ name: 'JoinLeague' });
  }
};
//const userRoles = ["user", "super", "admin"]

onMounted(() => {
  //login();
});
</script>

<style scoped>
  </style>