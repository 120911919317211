<template>
  <div>
    <h1>League</h1>
      <table>
        <tr><td>League Id</td><td>{{league.id}}</td></tr>
        <tr><td>League Name</td><td>{{ league.name }}</td></tr>
        <tr><td>Active Race</td><td><a v-bind:href="league.active_race_url" target="prosite">{{ league.activeRace }}</a></td></tr>
      </table>
      <h2>Player Draft Lists</h2>
      <button @click="randomizeOrder()" >Randomize Draft Order </button>
      <button @click="runDraft()" :disabled="round >12" >Draft! {{ round }}</button>
      <button @click="submitDraft()" :disabled="round !=13" >Submit Draft Result</button>
      <table>
        <thead>
          <tr >
              <th v-for="player in players" :key="player.player_strava_id">{{ player.player_nickname }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="draft in draftLists" :key="draft">
              <div>{{ draft.user_strava_id }}</div>
              <div v-for="r in draft.racer_list" :key="r">
                <span :class="getStatus(r, draft)" >{{ r.name }}</span>
                <span>[{{ r.player }}]</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useLeagueStore } from '@/stores/league';
import { useRacerStore } from '@/stores/racer';

const leagueStore = useLeagueStore()
const racerStore = useRacerStore()

const league = ref(leagueStore.league);
const players = computed(() => {
  return leagueStore.players;
});

const draftLists = computed( () => {
  return racerStore.draftList;
})

const getAllDraftlist = racerStore.getAllDraftlist;
onMounted(() => {
  getAllDraftlist(league.value.id);
});

function randomizeOrder() {
  leagueStore.shuffle();
  getAllDraftlist(league.value.id);
  round.value = 1;
}

const round = ref(1);

function runDraft() {
  if(round.value == 13) return;
  const dl = draftLists.value;
  const lists = round.value % 2 == 1?dl:dl.slice().reverse();
  lists.forEach( list => {
    let next = list.racer_list.find( racer => !racer.player)
    lists.forEach ( l => {
      let took = l.racer_list.find( racer => racer.name == next.name)
      took.player = list.user_strava_id.toString();
      //console.log(list.user_strava_id + " took " + took.name)
    })
  } );
  round.value++;
}

function submitDraft() {
  const ps = players.value;
  const dls = draftLists.value;
  ps.forEach( (p, i) => {
    const racers = dls[i].racer_list.filter( racer => racer.player == p.player_strava_id);
    console.log(p.player_nickname + ": " + racers.length);
    p.active_racers = racers.slice(0, 8);
    p.bench_racers = racers.slice(8);
  });
  leagueStore.submitDraft();
}

function getStatus(r, draft) {
  if (!r.player) return '';
  return (r.player == draft.user_strava_id) ? 'own' : 'taken';
}
</script>

<style scoped>
.own {
  background-color: greenyellow;
}
.taken {
  background-color: #eee;
}
</style>