import { defineStore } from 'pinia';
import axios from 'axios';

import { useLeagueStore } from './league';

export const useRacerStore = defineStore('racer', {
  state: () => ({
    allRacers: [], // this is for trade
    racers: [], // this is for draft
    draftList: [] // this is for draft admin
  }),
  actions: {
    async getRacerlist(raceurl) {
      const token = localStorage.getItem('token');
      const racerResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/racer/race?race_url=${raceurl}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const leagueStore = useLeagueStore();
      const m = new Map();
      leagueStore.players.forEach( p => {
        if(p.active_racers) p.active_racers.forEach( r => {m.set(r.url, p.player_strava_id)})
        if(p.bench_racers) p.bench_racers.forEach( r => {m.set(r.url, p.player_strava_id)})
      });

      racerResponse.data.forEach( r => {
        r.player = m.has(r.url)?m.get(r.url):null;
      });

      this.allRacers = racerResponse.data;

      // update ownership by using League Player information
      //this.getLeaguePlayers(this.league.id);
    },
    async loadDraftlist(raceurl) {
      const token = localStorage.getItem('token');
      const racerResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/racer/draftlist?race_url=${raceurl}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      this.racers = racerResponse.data;
      //this.getLeaguePlayers(this.league.id);
    },
    async updateDraftlist(raceurl) {
      const token = localStorage.getItem('token');
      await axios.put(`${process.env.VUE_APP_API_BASE_URL}/racer/draftlist?race_url=${raceurl}`, this.racers, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      //this.players = leagueResponse.data;
    },
    async getAllDraftlist(league_id) {
      const token = localStorage.getItem('token');
      const racerResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/racer/alldraftlists?league_id=${league_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const leagueStore = useLeagueStore();
      const leagueplayers = leagueStore.players;
      const draftlists = racerResponse.data;
      // clean up
      draftlists.forEach(list => {
        list.racer_list.forEach(r => {
          r.player = null;
        })
      });
      this.draftList = [];
      leagueplayers.forEach(player => {
        this.draftList.push(draftlists.find(list => list.user_strava_id == player.player_strava_id));
      });
    },
    insertBefore(racername, beforeRacer) {
      let r = this.racers.find((rac) => rac.name == racername)
      let b = this.racers.find((rac) => rac.name == beforeRacer)
      let pos = this.racers.indexOf(b)
      let rem = this.racers.indexOf(r)
  
      console.log(r + " " + b + " " + pos + " " + rem)
  
      this.racers.splice(rem, 1)
      //console.log(this.racers.length)
      this.racers.splice(pos, 0, r)
      //console.log(this.racers.length)
      //this.racers = []
    },
    move(position, direction) {
      if(position == 0 && direction == 'up') return;
      if(position == this.racers.length-1 && direction != 'up') return;

      let r = this.racers.splice(position, 1);
      if(direction == 'top') {
        this.racers.splice(0, 0, r[0]);
      }
      else this.racers.splice(direction=='up'?position-1:position+1, 0, r[0]);
    },
  },
});