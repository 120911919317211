<template>
  <div>
    <h1 class = "mt-4">{{ league.name }}</h1>
    <!-- Button trigger modal -->
      <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">
        League Info
      </button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">League Info</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <table>
        <tr><td>Id</td><td>{{league.id}}</td></tr>
        <tr><td>Name</td><td>{{ league.name }}</td></tr>
        <tr><td>Password</td><td>{{ league.password }}</td></tr>
        <tr><td>Active Race</td><td><a v-bind:href="league.active_race_url" target="prosite">{{ league.activeRace }}</a></td></tr>
      </table>
      </div>
    </div>
  </div>
</div>
<router-link class="nav-link text-decoration-none" to="/newsfeed">
  <h3 class=" mt-4 text-success" style="text-decoration: underline;" >Newsfeed</h3>
</router-link>
<div v-if="announcements.length != 0">
  <div class="container w-75 h-50">
    <div class="announcements-container">
        <div v-for="a in announcements" :key="a" class="alert alert-success alert-dismissible fade show" role="alert">
          <span v-html="a.message"></span>
        </div>
    </div>
  </div>
</div>
<table class="table mt-4">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Username</th>
      <th scope="col">Points</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(player,index) in players" :key="player.player_strava_id">
            <td>{{ index + 1}}</td>
            <td v-if="player.player_strava_id != leagueStore.leaguePlayer.player_strava_id"><router-link :to="{ path: '/rosterview', query: { userId: player.player_strava_id }}" > {{ player.player_nickname }} </router-link></td>
            <td v-else><router-link :to="{ path: '/roster'}" > {{ player.player_nickname }} </router-link></td>
            <td><router-link :to="{ path: '/detailscore', query: { userId: player.player_strava_id }}" > {{ player.current_score }} </router-link></td>
    </tr>
  </tbody>
</table>
  </div>
</template>



<script setup>
import { computed, ref, onMounted } from 'vue';
import { useLeagueStore } from '@/stores/league';
const leagueStore = useLeagueStore()

const league = ref(leagueStore.league);
const players = computed(() => {

  let copy = leagueStore.players.slice();
  copy.sort((a,b) => {return b.current_score - a.current_score});

  //console.log(JSON.stringify(copy));
  return copy//leagueStore.players//.slice().sort((a,b) => a.current_score - b.current_score);
});

const announcements = computed(() => {
  return leagueStore.announcements.slice().reverse();
})

const getPlayers = leagueStore.getLeaguePlayers;
onMounted(() => {
  getPlayers(league.value.id);
  leagueStore.getRace();
  leagueStore.getAnnouncements();
});


</script>

<style scoped>
.announcements-container {
  height: 220px;
  overflow-y: auto;
}

router-link {
  color: #198754; /* Bootstrap success color */
}

/* Optional: Hover and active states */
a:hover,
a:focus,
a:active,
router-link:hover,
router-link:focus,
router-link:active {
  color: #157347; /* Darker shade for hover, focus, active states */
}

</style>