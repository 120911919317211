<template>
  <div>
    <h1>My Routes</h1>
    <table>
      <tr>
        <th>Name</th><th>Distance</th><th>Elevation</th>
      </tr>
      <tr v-for="route in routes" :key="route.id">
        <td>{{ route.name }}</td><td>{{ route.distance }}</td><td>{{ route.elevation_gain }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      routes: []
    };
  },
  async created() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ name: 'Login' });
    } else {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/routes`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.routes = response.data;
      } catch (error) {
        console.error('Error fetching routes:', error);
        this.$router.push({ name: 'Login' });
      }
    }
  }
}
</script>