import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import Login from '../views/LoginView.vue'
import Callback from '../views/CallbackView.vue'
import Activities from '../views/ActivitiesView.vue'
import Routes from '../views/RoutesView.vue'
import Stats from '../views/StatsView.vue'
import Logout from '../views/LogoutView.vue'
import Profile from '../views/ProfileView.vue'
import JoinLeague from '../views/JoinLeagueView.vue'
import LeagueHome from '@/views/LeagueHome.vue'
import LeaguePreview from '@/views/LeaguePreview.vue'
import DraftList from '@/views/DraftListView.vue'
import Roster from '@/views/RosterView.vue'
import RunDraft from '@/views/RunDraftView.vue'
import Trade from '@/views/TradeView.vue'
import RunTrade from '@/views/RunTrade.vue'
import RunScore from '@/views/RunScoresView.vue'
import ByPass from '@/views/ByPass.vue'
import DetailScore from '@/views/DetailScoresView.vue'
import RosterView from '@/views/RosterViewOnly.vue'
import FullScoreView from '@/views/FullScoresView.vue'
import RaceMaintenanceView from '@/views/RaceMaintenanceView.vue'
import AutomationCenterView from '@/views/AutomationCenterView.vue'
import CreateLeagueView from '@/views/CreateLeagueView.vue'
import NewsfeedView from '@/views/NewsfeedView.vue'
import LiveDraftView from '@/views/LiveDraftView.vue'
import RunLiveDraftView from '@/views/RunLiveDraftView.vue'
import LeagueMaintenanceView from '@/views/LeagueMaintenanceView.vue'
import { useAuthStore } from '@/stores/authentication'

const routes = [
  {
    path: '/leaguecenter',
    name: 'LeagueMaintenanceView',
    component: LeagueMaintenanceView,
  },
  {
    path: '/runlivedraft',
    name: 'RunLiveDraftView',
    component: RunLiveDraftView,
  },
  {
    path: '/livedraft',
    name: 'LiveDraftView',
    component: LiveDraftView,
  },
  {
    path: '/newsfeed',
    name: 'NewsfeedView',
    component: NewsfeedView,
  },
  {
    path: '/createleague',
    name: 'CreateLeagueView',
    component: CreateLeagueView,
  },
  {
    path: '/autocenter',
    name: 'AutomationCenterView',
    component: AutomationCenterView,
  },
  {
    path: '/racecenter',
    name: 'RaceMaintenanceView',
    component: RaceMaintenanceView,
  },
  {
    path: '/leaguepreview',
    name: 'LeaguePreview',
    component: LeaguePreview,
    meta: { public: true }
  },
  {
    path: '/fullscores',
    name: 'FullScoreView',
    component: FullScoreView,
  },
  {
    path: '/rosterview',
    name: 'RosterView',
    component: RosterView,
  },
  {
    path: '/detailscore',
    name: 'DetailScore',
    component: DetailScore,
  },
  {
    path: '/bypass',
    name: 'ByPass',
    component: ByPass,
    meta: { public: true }
  },
  {
    path: '/runscore',
    name: 'RunScore',
    component: RunScore
  },
  {
    path: '/runtrade',
    name: 'RunTrade',
    component: RunTrade
  },
  {
    path: '/trade',
    name: 'Trade',
    component: Trade
  },
  {
    path: '/rundraft',
    name: 'RunDraft',
    component: RunDraft
  },
  {
    path: '/roster',
    name: 'Roster',
    component: Roster
  },
  {
    path: '/draftlist',
    name: 'DraftList',
    component: DraftList
  }, 
  {
    path: '/joinleague',
    name: 'JoinLeague',
    component: JoinLeague
  }, 
  {
    path: '/leaguehome',
    name: 'LeagueHome',
    component: LeagueHome
  }, 
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  }, 
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { public: true }
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { public: true }
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback,
    meta: { public: true }
  },
  {
    path: '/activities',
    name: 'Activities',
    component: Activities
  },
  {
    path: '/routes',
    name: 'Routes',
    component: Routes
  },
  {
    path: '/stats',
    name: 'Stats',
    component: Stats
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: { public: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  console.log("to: " + to.path)
  console.log("private? " + !to.meta.public + " auth? " + !authStore.isAuthenticated)
  if (!to.meta.public && !authStore.isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router
