<template>
  <h2 class = "mt-4">My Team</h2>
  <div v-if="isMyTurn" class="alert alert-success alert-dismissible fade show"> Your Turn</div>
    <div class="d-flex justify-content-center mt-3">
      <div class="d-flex flex-wrap justify-content-center mx-2">
        <div v-for="r in myTeam" :key="r" class="card mb-3 mx-2 p-2" style="width: 18rem;">
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column align-items-center">
                <img :src="r.teamimg?r.teamimg:'blank-profile-picture.png'" class="img-fluid" alt="Racer Image" style="width: 50px; height: 50px;">
                <p class="text-muted text-center" style="font-size: 13px;">#{{ r.bib }}</p>
            </div>
            <div class="content-wrapper">
              <h5 class="card-title"><a :href="r.url" target="prosite">{{ r.name }}</a></h5>
              <p class="card-text" v-if="r.specialties.length > 0">{{ r.specialties.join(', ') }}</p>
              <p class="card-text" v-else>None</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  <h2 class = "mt-4">Available Racers</h2>
  <span v-for="s in specialties" :key="s" class="px-2">
      <input type="checkbox" :id="s" :value="s" v-model="specialtiesFilter">
      <label :for="s">&nbsp;{{s}}</label>
  </span>
  <span class="px-2">
      <input type="text" v-model="textFilter">
      <label>&nbsp;Search</label>
  </span>
  <div class="d-flex justify-content-center mt-3">
      <button type="button" class="btn btn-success" @click="submitSelection()" :disabled="!selectedRacer || !isMyTurn">Submit {{ nextSeq + 1 }} </button>
  </div>
    <div class="d-flex flex-wrap justify-content-center mt-3 mx-2">
      <div v-for="r in availableRacers" :key="r" @click="selectRacer(r)" class="card mb-3 mx-2 p-2" :class="{ 'border-success-thick': selectedRacer === r }" style="width: 18rem; cursor: pointer;">
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column align-items-center">
            <img :src="r.teamimg?r.teamimg:'blank-profile-picture.png'" class="img-fluid" alt="Racer Image" style="width: 50px; height: 50px;">
            <!--p>{{ r.bib }}</p-->
            <p class="text-muted text-center" style="font-size: 13px;">#{{ r.bib }}</p>
          </div>
          <div class="content-wrapper">
            <h5 class="card-title px-2"><a :href="r.url" target="prosite">{{ r.name }}</a></h5>
            <p class="card-text px-2" v-if="r.specialties.length > 0">{{ r.specialties.join(', ') }}</p>
            <p class="card-text px-2" v-else>None</p>
          </div>
        </div>
        
      </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import axios from 'axios';

import { useRacerStore } from '@/stores/racer';
import { useLeagueStore} from '@/stores/league';

const leagueStore = useLeagueStore();

const racerStore = useRacerStore();
const availableRacers = computed(() => {
  return racerStore.allRacers.filter( r => !r.player && (specialtiesFilter.value.length === 0 || specialtiesFilter.value.every(f => r.specialties.includes(f))))
                             .filter( r => r.name.toLowerCase().includes(textFilter.value.toLowerCase()))
});
const specialties = ['GC', 'TT', 'Sprinter', 'One day races', 'Hills', 'Climber', 'Youth'];
const specialtiesFilter = ref([]);

const textFilter = ref("");

const racerMap = computed (()=> {
  return new Map(racerStore.allRacers.slice().map( r=> [r.url, r]));
});
const myTeam = computed( () => {
  return racerStore.allRacers.filter( r => r.player == leagueStore.leaguePlayer.player_strava_id )
});

const selectedRacer = ref(null);
const selectRacer = (racer) => {
  selectedRacer.value = racer;
};

const nextSeq = ref(0);

const submitSelection = async () => {
  await axios.post(`${process.env.VUE_APP_API_BASE_URL}/livedraft/draftracer`, {
    league_id: leagueStore.league.id,
    player_id: leagueStore.leaguePlayer.player_strava_id,
    racer_url: selectedRacer.value.url,
    seq: nextSeq.value
  })
    .then(function (response) {
      // handle success
      console.log(response);
    })
  selectedRacer.value = null;
};

const myTurns = ref([]);

const isMyTurn = computed(() => {
  return (myTurns.value.indexOf(nextSeq.value)!=-1)
});

const controller = new AbortController();

const getStreamAxios = async () => {
  try {
    console.log('Sending request');
    const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/livedraft/stream/${leagueStore.league.id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        Accept: 'text/event-stream',
        'Content-Type': 'application/json',
      },
      signal: controller.signal
    });

    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      for(;;) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        const chunk = decoder.decode(value, { stream: true });
        console.log(chunk);
        const events = leagueStore.parseChunk(chunk);
        events.forEach( e => {
          console.log(e.eventType)
          const msg = JSON.parse(e.data)
          if(e.eventType === 'draft_position') {
            myTurns.value = msg
          } else {
            nextSeq.value = msg.slice(-1)[0].seq+1
            msg.slice().forEach( s => {
              racerMap.value.get(s.racer_url).player = s.player_id
            });
            if (selectedRacer.value && racerMap.value.get(selectedRacer.value.url).player) selectedRacer.value = null;
          }
        })
      }
    }

    console.log('Request sent');
  } catch (error) {
    console.log(error)
  }
}

onMounted(() => {
  racerStore.getRacerlist(leagueStore.league.active_race_url);
  getStreamAxios();
});

onUnmounted( () => {
  controller.abort()
});
</script>

<style scoped>
.border-success-thick {
  border: 4px solid #28a745; /* Bootstrap success green with thicker border */
}

.image-parent {
  width: 50px; /* Adjust width of the image container */
  margin-right: 10px; /* Adjust margin between image and content */
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.content-wrapper {text-align: left;
}

</style>