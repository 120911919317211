<template>
  <div>
    <h1 class = "mt-4">My Roster</h1>
    <div class="d-flex justify-content-center mt-3">
      <table>
        <tr>
          <td>
            <button class="btn btn-success btn-lg mb-3 me-3" @click="leagueStore.submitRoaster()" :disabled="activeRacers.length != 8 || (new Date().getHours() > 5 && new Date().getHours() < 13)">Save</button>
            <router-link class="btn btn-outline-success btn-lg mb-3" to="/trade">Trade</router-link>
          </td>
        </tr>
        <tr>
          <td>
            <div @drop="onDrop($event, 'active')" @dragover.prevent>
              <ul class="list-group">
                <li
                  v-for="racer in activeRacers"
                  :key="racer.id"
                  class="list-group-item d-flex justify-content-between align-items-center"
                  draggable="true"
                  @dragstart="startDragging($event, racer)"
                >
                  <div class="d-flex align-items-center">
                    <div class="d-flex flex-column align-items-center">
                      <img :src="racer.teamimg?racer.teamimg:'blank-profile-picture.png'" class="img-fluid" alt="Racer Image" style="width: 50px; height: 50px;">
                      <!--p>{{ r.bib }}</p-->
                      <p class="text-muted text-center" style="font-size: 13px;">#{{ racer.bib }}</p>
                    </div>
                    <div class="content-wrapper">
                      <h5 class="card-title px-2"><a :href="racer.url" target="prosite">{{ racer.name }}</a></h5>
                      <p class="card-text px-2" v-if="racer.specialties.length > 0">{{ racer.specialties.join(', ') }}</p>
                      <p class="card-text px-2" v-else>None</p>
                    </div>
                  </div>
                  <div class="btn-group ms-2" role="group" aria-label="Basic example">
                      <!--div class="border-end">
                      <button type="button" class="btn btn-success" :disabled="idx === 0">↑</button>
                    </div -->
                    <div>
                      <button @click="leagueStore.moveRoaster(racer.name, 'bench')"  type="button" class="btn btn-success">↓</button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div @drop="onDrop($event, 'bench')" class="drop-zone" @dragover.prevent>
              <ul class="list-group">
                <li
                  v-for="racer in benchRacers"
                  :key="racer.id"
                  class="list-group-item list-group-item-secondary d-flex justify-content-between align-items-center"
                  draggable="true"
                  @dragstart="startDragging($event, racer)"
                >
                <div class="d-flex align-items-center">
                  <div class="d-flex flex-column align-items-center">
                    <img :src="racer.teamimg?racer.teamimg:'blank-profile-picture.png'" class="img-fluid" alt="Racer Image" style="width: 50px; height: 50px;">
                    <!--p>{{ r.bib }}</p-->
                    <p class="text-muted text-center" style="font-size: 13px;">#{{ racer.bib }}</p>
                  </div>
                  <div class="content-wrapper">
                    <h5 class="card-title px-2"><a :href="racer.url" target="prosite">{{ racer.name }}</a></h5>
                    <p class="card-text px-2" v-if="racer.specialties.length > 0">{{ racer.specialties.join(', ') }}</p>
                    <p class="card-text px-2" v-else>None</p>
                  </div>
                </div>
                  <div class="btn-group ms-2" role="group" aria-label="Basic example">
                      <div class="border-end">
                      <button @click="leagueStore.moveRoaster(racer.name, 'active')" type="button" class="btn btn-success" >↑</button>
                    </div>
                    <!--div>
                      <button type="button" class="btn btn-success" :disabled="idx === benchRacers.length - 1">↓</button>
                    </div-->
                  </div>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useLeagueStore } from '@/stores/league';

const leagueStore = useLeagueStore();

const activeRacers = computed(() => leagueStore.leaguePlayer.active_racers?leagueStore.leaguePlayer.active_racers:[]);
const benchRacers = computed(() => leagueStore.leaguePlayer.bench_racers?leagueStore.leaguePlayer.bench_racers:[]);

function startDragging(event, racer) {
  event.dataTransfer.dropEffect = 'move';
  event.dataTransfer.effectAllowed = 'move';
  event.dataTransfer.setData('racerName', racer.name);
}

function onDrop(event, target) {
  const racerName = event.dataTransfer.getData('racerName');
  leagueStore.moveRoaster(racerName, target);
  event.preventDefault();
}
</script>

<style scoped>
.image-parent {
  width: 50px; /* Adjust width of the image container */
  margin-right: 10px; /* Adjust margin between image and content */
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.content-wrapper {text-align: left;
}

</style>
