import { defineStore } from 'pinia';
import axios from 'axios';
import { useAuthStore } from './authentication';
import { useRacerStore } from './racer';

export const useLeagueStore = defineStore('league', {
  state: () => ({
    league: {},
    players: [],
    race: {},
    leaguePlayer: {},  //current player
    tradeList: [],
    scoresForStage: [], // scores for current selected stage
    stagePlayers: [],
    announcements: [],
    allLeagues: [],
  }),
  actions: {
    resetData() {
      this.players= [];
      this.race= {};
      this.leaguePlayer= {}  //current player
      this.tradeList= []
      this.scoresForStage= [] // scores for current selected stage
      this.stagePlayers= []
      this.announcements= []
      this.allLeagues= []
    },
    async getLeagueList() {
      const token = localStorage.getItem('token');
      const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/league`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      this.allLeagues = leagueResponse.data;
    },
    getPlayerByStravaId(stravaId){
      return this.players.find(p => p.player_strava_id == stravaId)
    },
    getPlayerPositionByStravaId(stravaId){
      return this.players.indexOf(this.players.find(p => p.player_strava_id == stravaId))
    },
    shuffle() {
      let currentIndex = this.players.length;
    
      // While there remain elements to shuffle...
      while (currentIndex != 0) {
    
        // Pick a remaining element...
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
    
        // And swap it with the current element.
        [this.players[currentIndex], this.players[randomIndex]] = [
          this.players[randomIndex], this.players[currentIndex]];
      }
    },
    async submitDraft() {
        const token = localStorage.getItem('token');
        await axios.put(`${process.env.VUE_APP_API_BASE_URL}/league/draftresult`, this.players.slice().reverse(), {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        //this.league = leagueResponse.data;
    },
    async join(code, nickname) {
      if (code) {
        const token = localStorage.getItem('token');
        const leagueResponse = await axios.put(`${process.env.VUE_APP_API_BASE_URL}/league/join?code=${code}&nickname=${nickname}`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.league = leagueResponse.data;
        this.resetData();
      }
    },
    async getRace() {
      const token = localStorage.getItem('token');
      const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/race/url?url=${this.league.active_race_url}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      this.race = leagueResponse.data;
      //this.getLeaguePlayers(this.league.id);
    },
    getStageNameByURL(stageurl) {
      let day = this.race.days.find(d => {d.url == stageurl});
      if (day) return day.name
      else return "--"
      //this.getLeaguePlayers(this.league.id);
    },
    async getRaceScoreByStage(stageUrl) {
      const token = localStorage.getItem('token');
      const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/racerScore/search?stage=${stageUrl}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      this.scoresForStage = leagueResponse.data;

      let racerToAwardsMap = Map.groupBy(this.scoresForStage, ({racer}) => racer);
      const racerStore = useRacerStore();
      let stagePlayers = this.players.slice();
      stagePlayers.forEach( p => {
        if (p.stage_scores && p.stage_scores.find(s => s.stage_url == stageUrl)) {
          p.stageRacers=p.stage_scores.find(s => s.stage_url == stageUrl).racers.slice();
        }
        else p.stageRacers = p.active_racers.slice();
        p.stageRacers.forEach(r => {
          r.scores = null;
          if(racerToAwardsMap.has(r.url)) {
            r.scores = racerToAwardsMap.get(r.url);
            racerToAwardsMap.delete(r.url);
          }
        });
      })
      const dummyPlayer = {player_nickname: "N/A", stageRacers: []};
      racerToAwardsMap.forEach((scores, racerurl) => {
        let racer = structuredClone(racerStore.allRacers.find(r => r.url == racerurl));
        racer.scores = scores;
        dummyPlayer.stageRacers.push(racer);
      })
      stagePlayers.push(dummyPlayer);

      let bonus = [0, 0, 20, 30, 50]
      // calculate total score for the stage
      stagePlayers.forEach(p => {
        p.stageScore = 0;
        p.jerseys = [];
        p.stageRacers.forEach(r => {
          if (!r.scores) return;
          r.scores.forEach(s => {
            p.stageScore += s.point;
            if (s.point ==0) p.jerseys.push(s.category);
          })
        })
        p.stageScore += bonus[p.jerseys.length];
      })
      this.stagePlayers = stagePlayers;
    },
    async submitStageScore(stageurl) {

      let maxScore = 0;
      let maxPerson = null;
      console.log("Calculating score for players: " + this.players)
      this.players.forEach( p => {
        let ss = {
          stage_url: stageurl,
          total_score: p.stageScore,
          racers: p.stageRacers
        }
        if(p.stageScore > maxScore) {
          maxScore = p.stageScore;
          maxPerson = p.player_nickname;
        }
        if(!p.stage_scores) p.stage_scores = [];
        let idx = p.stage_scores.findIndex(s => s.stage_url == stageurl);
        if( idx != -1) p.stage_scores.splice(idx, 1, ss);
        else p.stage_scores.push(ss);

        p.current_score = p.stage_scores.reduce((n, {total_score}) => n + total_score, 0);

        const token = localStorage.getItem('token');
        axios.put(`${process.env.VUE_APP_API_BASE_URL}/league/leagueplayers`, 
          p, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        p.stageScore = 0;
        p.stageRacers = [];
      })

      // post announcement
      console.log(this.race.days)
      let message =  "New scores are in! <strong>" + maxPerson + "</strong> scored " + maxScore;  
      const token = localStorage.getItem('token');
      await axios.post(`${process.env.VUE_APP_API_BASE_URL}/league/announcement`, {"message": message, "expiration": Date.now()+10800000, "league_id": this.league.id}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }); 
    },
    async loadLeague(leagueId) {
      const token = localStorage.getItem('token');
      const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/league/id/${leagueId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      this.league = leagueResponse.data;
      this.resetData();
      //this.getLeaguePlayers(this.league.id);
    },
    async getLeaguePlayers(leagueId) {
      const token = localStorage.getItem('token');
      const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/league/${leagueId}/players`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const auth = useAuthStore();
      this.leaguePlayer = leagueResponse.data.find((player) => {return player.player_strava_id == auth.user.strava_id});
      //console.log("PLAYER: " + this.leaguePlayer.active_racers);
      // sort by draft order
      let sortedPlayers = [leagueResponse.data.length];
      leagueResponse.data.forEach(p => {
        sortedPlayers[this.league.players.indexOf(p.player_strava_id)] = p;
        // sort stage scores newest first
        if(p.stage_scores) p.stage_scores.sort((a,b) => parseInt(b.stage_url.substring(b.stage_url.lastIndexOf("-")+1)) -  parseInt(a.stage_url.substring(a.stage_url.lastIndexOf("-")+1)));
      });
      this.players = sortedPlayers;
      console.log("Players retrieved: " + this.players)
    },
    moveRoaster(racername, target) {
      let allRacers = this.leaguePlayer.active_racers.concat(this.leaguePlayer.bench_racers);
      let r = allRacers.find((rac) => rac.name == racername)
      if(this.leaguePlayer.active_racers) {
        this.leaguePlayer.active_racers = this.leaguePlayer.active_racers.filter((racer) => {console.log(racer.name == racername);  return racer.name !== racername})
      } else this.leaguePlayer.active_racers = []
      if(this.leaguePlayer.bench_racers) {
        this.leaguePlayer.bench_racers = this.leaguePlayer.bench_racers.filter((racer) => racer.name !== racername)
        //console.log(this.leaguePlayer.active_racers)
      } else this.leaguePlayer.bench_racers = []
      if (target == 'active') {
        this.leaguePlayer.active_racers.push(r);
      } else {
        this.leaguePlayer.bench_racers.push(r);
      }
    },
    async submitRoaster() {
      const token = localStorage.getItem('token');
      const leagueResponse = await axios.put(`${process.env.VUE_APP_API_BASE_URL}/league/leagueplayers`, this.leaguePlayer, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
        //this.league = leagueResponse.data;
      console.log("submit roaster: " + leagueResponse)

      await axios.post(`${process.env.VUE_APP_API_BASE_URL}/league/announcement`, {"message": "<strong>" + this.leaguePlayer.player_nickname + "</strong> updated roster!", "expiration": Date.now()+10800000, "league_id": this.league.id}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });   
      if (leagueResponse.status == 200) alert("Roster change successful.");   
    },
    async submitTrade(fromRacer, toRacer) {
      fromRacer.player = this.leaguePlayer.player_strava_id.toString();
      const token = localStorage.getItem('token');
      const leagueResponse = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/league/trade?league_id=${this.league.id}`, {"fromRacer": fromRacer, "toRacer": toRacer}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      this.tradeList = leagueResponse.data

      await axios.post(`${process.env.VUE_APP_API_BASE_URL}/league/announcement`, {"message": "<strong>" + this.leaguePlayer.player_nickname + "</strong> submitted a trade request!", "expiration": Date.now()+10800000, "league_id": this.league.id}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }); 
    },
    async getTradeRequests() {
      const token = localStorage.getItem('token');
      const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/league/traderequests?league_id=${this.league.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      let tradeList = leagueResponse.data.slice();
      tradeList.sort( (a, b) => {
        let r = a.toRacer.ranking - b.toRacer.ranking;
        if (r == 0) {
          let pb = this.players.indexOf(this.players.find( player => player.player_strava_id == b.player_strava_id));
          let pa = this.players.indexOf(this.players.find( player => player.player_strava_id == a.player_strava_id));
          return pa-pb;
        } else return r;
      });

      // select trade
      const tradedRacer = [];
      const tradedPlayer = [];
      tradeList.forEach(trade => {
        if (tradedRacer.findIndex(tr => tr.url == trade.toRacer.url) == -1 && tradedPlayer.indexOf(trade.player_strava_id) == -1) {
          tradedRacer.push(trade.toRacer);
          tradedPlayer.push(trade.player_strava_id);
          trade.selected = true;
        }
      });
      this.tradeList = tradeList;
    },
    async getMultiTradeRequests() {
      const token = localStorage.getItem('token');
      const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/league/traderequests?league_id=${this.league.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      let tradeList = leagueResponse.data.slice();

      const tradePriority = this.league.players.slice();
      const map = Map.groupBy(tradeList, ({player_strava_id}) => player_strava_id);
      //let tLists = Array(this.players.length).fill([]);

      const tradedRacers = [];
      while(map.size>0){
        tradePriority.forEach((p) => {
          let requestList = map.get(p);
          if(requestList) {
            while(requestList.length){
              let request = requestList.shift();
              //console.log(request.player_strava_id + " " + request.fromRacer.name + " >> " + request.toRacer.name);
              //console.log(tradedRacers);
              if( !(tradedRacers.includes(request.toRacer.url)) && !(tradedRacers.includes(request.fromRacer.url)) ){
                tradedRacers.push(request.fromRacer.url);
                tradedRacers.push(request.toRacer.url);
                request.selected = true;
                break;
              }
              
            }
            if(requestList.length ==0) map.delete(p);
          }
        });
      }

      this.tradeList = tradeList;
    },
    async cancelTradeRequest(id){
      const token = localStorage.getItem('token');
      await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/league/canceltrade?id=${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      await this.getTradeRequests();
    },
    async completeTradeRequests() {
      let tradedPlayer = []
      let accepted = this.tradeList.filter( req => req.selected);
      accepted.forEach(req => {
        let p = this.players.find( pr => pr.player_strava_id == req.player_strava_id)
        let rlist = p.active_racers.findIndex(ar => ar.url == req.fromRacer.url) == -1?p.bench_racers:p.active_racers;
        rlist.splice(rlist.findIndex(ar => ar.url == req.fromRacer.url), 1, req.toRacer);
        tradedPlayer.push(p);
      });
      tradedPlayer.sort((a, b) => { this.league.players.indexOf(b.player_strava_id) - this.league.players.indexOf(a.player_strava_id)})
      tradedPlayer.forEach( p => {
        this.players.splice(this.players.indexOf(p), 1);
        this.players.push(p);
      })

      const token = localStorage.getItem('token');
      await axios.put(`${process.env.VUE_APP_API_BASE_URL}/league/completetrade`, 
        {"accepted": accepted, "players": this.players}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // post announcement
      let message = accepted.length>0? "Trade results: <br>":"No trade completed."
      accepted.forEach( req => {
        message = message.concat("<strong>" +this.getPlayerByStravaId(req.player_strava_id).player_nickname + "</strong> acquired <strong>" + req.toRacer.name + "</strong><br>");
      })
      await axios.post(`${process.env.VUE_APP_API_BASE_URL}/league/announcement`, {"message": message, "expiration": Date.now()+10800000, "league_id": this.league.id}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }); 
    },
    async getAnnouncements(){
      const token = localStorage.getItem('token');
      const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/league/newannouncements?league_id=${this.league.id}&expiration=${Date.now()}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      this.announcements = leagueResponse.data;
    },
    async getAllAnnouncements() {
      const token = localStorage.getItem('token');
      const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/league/announcements?league_id=${this.league.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      this.announcements = leagueResponse.data;
    },
    parseChunk(chunk) {
        // Events are separated by two newlines
      const events = chunk.split(/\r\n\r\n|\r\r|\n\n/g);
      if (events.length === 0) return;
      const eventList = [];

      for (const eventChunk of events) {
          let eventType = '';
          // Split up by single newlines.
          const lines = eventChunk.split(/\n|\r|\r\n/g);
          let eventData = '';
          for (const line of lines) {
              const lineMatch = /([^:]+)(?:: ?(.*))?/.exec(line);
              if (lineMatch) {
                  const field = lineMatch[1];
                  const value = lineMatch[2] || '';

                  switch (field) {
                      case 'event':
                          eventType = value;
                          break;
                      case 'data':
                          eventData += value;
                          eventData += '\n';
                          break;
                      case 'id':
                          // The ID field cannot contain null, per the spec
                          if (!value.includes('\0')) this.lastEventId = value;
                          break;
                      // We do nothing for the `delay` type, and other types are explicitly ignored
                  }
              }
          }


          // https://html.spec.whatwg.org/multipage/server-sent-events.html#dispatchMessage
          // Skip the event if the data buffer is the empty string.
          if (eventData === '') continue;

          if (eventData[eventData.length - 1] === '\n') {
              eventData = eventData.slice(0, -1);
          }

          eventList.push({eventType: eventType, data: eventData});
      }
      return eventList;
    }
  },
});