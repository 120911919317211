<template>
  <div>
    <h1>My Profile</h1>
      <table>
        <tr><td>User Name</td><td>{{ user.username }}</td></tr>
        <tr><td>First Name</td><td>{{ user.firstname }}</td></tr>
        <tr><td>Last Name</td><td>{{ user.lastname }}</td></tr>
        <tr><td>Location</td><td>{{ user.city }}, {{ user.state }}</td></tr>
        <tr><td>Roles</td><td>{{ user.roles }}</td></tr>
        <tr><td>Roles</td><td>
          <ul>
            <li v-for="r in userRoles" :key="r.id">
              <input type="checkbox" :id="r" :value="r" v-model="user.roles" disabled="disabled">
              <label :for="r.id">{{r}}</label>
            </li>
          </ul>
        </td></tr>
        <tr><td>Profile Pic</td><td><img v-bind:src="user.profile_pic" /></td></tr>
      </table>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useAuthStore } from '@/stores/authentication';
const authStore = useAuthStore()

const user = ref(authStore.user)
/*const login = () => {
  authStore.login();
};*/

const userRoles = ["user", "super", "admin"]

onMounted(() => {
  //login();
});
</script>