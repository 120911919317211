<template>
  <div>
    <h1 class = "mt-4">Create League</h1>
    <form>
      <div class="row gx-5 mb-3">
          <div class="col">
            <label for="exampleInputPassword1" class="form-label">League Name</label>
            <input v-model="leaguename" class="form-control" id="exampleInputPassword1">
          </div>
        </div>
        <div class="container px-4 text-center">
          <div class="row gx-t">
            <label for="raceselect" class="form-label">Race</label>
            <select v-model="selectedRace" id="raceselect">
              <option v-for="race in allRaces" :value="race" :key="race">{{race.name}}</option>
            </select>
          </div>
          <div class="row gx-5 mb-3">
            <div class="col">
              <label for="inputPassword" class="form-label">League Password</label>
              <input v-model="code" class="form-control" id="inputPassword" aria-describedby="emailHelp">
            </div>
          </div>
          <div class="row gx-5 mb-3">
            <div class="col">
              <label for="playernickname" class="form-label">Your Nickname</label>
              <input v-model="nickname" class="form-control" id="playernickname" aria-describedby="emailHelp">
            </div>
          </div>
          <div class="row gx-5">
            <div class="col">
              <button @click.prevent="create" class="btn btn-success">Create</button>
            </div>
          </div>
        </div>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useLeagueStore} from '@/stores/league'
const router = useRouter()

const leagueStore = useLeagueStore()

const code = ref("");
const leaguename = ref("");
const nickname = ref("");

const allRaces = ref([]);
const selectedRace = ref({});

const create = async () => {

  let newLeague = {
    name: leaguename.value,
    password: code.value,
    active_race_url: selectedRace.value.url,
    activeRace: selectedRace.value.name,
    admin: "ltseng",
    players: []
  }

  const token = localStorage.getItem('token');
  const leagueResponse = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/league/create-league`, newLeague, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  console.log(leagueResponse);
  await leagueStore.join(code.value, nickname.value);

  router.push({ name: 'LeagueHome' });
};

const loadRaces = async () => {
  const token = localStorage.getItem('token');
  const raceResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/race`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  allRaces.value = raceResponse.data.slice().sort((a,b) => Date.parse(b.start_date)-Date.parse(a.start_date));
  selectedRace.value = allRaces.value[0];
};

onMounted(() => {
  loadRaces();
});
</script>

<style scoped>
  </style>