import { defineStore } from 'pinia';

export const useAutoStore = defineStore('auto', {
  state: () => ({
    tasks: [],
    currentTask: null,
    isAutoMode: false
  }),
  actions: {
    flip() {
      this.isAutoMode = !this.isAutoMode;
    },
    startTask() {
      if (this.currentTask) return null;
      let task = this.tasks.find(t => { return !t.completed && new Date()>t.time});
      if (task) {
        this.currentTask = task;
        task.inProgress = true;
      }
      console.log("return " + task);
      return task;
    },
    completeTask() {
      this.currentTask.completed = true;
      this.currentTask = null;
    }
  },
});