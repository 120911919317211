<template>
  <h1 v-if="autoStore.isAutoMode" style="color:red;"><b>Auto</b></h1>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router'
import { useAutoStore } from '@/stores/automation';

const autoStore = useAutoStore();
const router = useRouter();

const timeoutId = ref(0);

const autopilot = () => {
  if (autoStore.isAutoMode) {
    // look for next task
    let task = autoStore.startTask();
    console.log("found task: " + task);
    if(task) router.push(task.path);    
  }
};

onMounted(async () => {
  let id = setInterval(autopilot, 1000*60*5);
  console.log(`autobot ${id} is loaded.`)
  timeoutId.value = id;
});

</script>

