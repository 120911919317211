<template>
  <div>
    <div id="intro" class="bg-image shadow-2-strong">
      <div class="container d-flex align-items-center justify-content-center text-center h-100">
        <div class="card text-bg-dark w-50 w-md-50">
  <h2 class="card-header">Sign In</h2>
  <div class="card-body">
    <button class="btn" @click="login"><img src="btn_strava_connectwith_orange.png" class="img-fluid"></button>
  </div>
</div>
        </div>
      </div>
    </div>
</template>

<script>

import { useAuthStore } from '@/stores/authentication';
import { useLeagueStore } from '@/stores/league';
export default {

  async created(){
    const token = localStorage.getItem('token');
    if(token) {
      //console.log("getting user info " + token);
      try {
        const authStore = useAuthStore()
        const leagueStore = useLeagueStore()

        await authStore.login()
        if (authStore.user.current_league_id) {
          await leagueStore.loadLeague(authStore.user.current_league_id);
          this.$router.push({ name: 'LeagueHome'});
        } else {
          this.$router.push({ name: 'JoinLeague' });
        }
      } catch (error) {
        console.error('Error during auto login:', error);
        localStorage.removeItem('token');
      }
    }
  },

  methods: {
    login() {
      window.location.href = `${process.env.VUE_APP_API_BASE_URL}/login`;
    }
  }
}
</script>

<style scoped>
    #intro {
      background-image: url("/public/background_image.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 100vh;
    }

  .btn img {
    max-width: 100%;
  }

    /* Height for devices larger than 576px */
    @media (min-width: 992px) {
      #intro {
        margin-top: -58.59px;
      }
    }
</style>