<template>
  <div>
    <h3>{{title}}</h3>
      <table>
        <tr>
          <th>Activities</th>
          <th>Distance</th>
          <th>Elev Gain</th>
          <th>Time</th>
        </tr>
        <tr>
          <td>{{rideTotals.count}}</td>
          <td>{{ rideTotals.distance }}</td>
          <td>{{ rideTotals.elevation_gain }}</td>
          <td>{{ rideTotals.moving_time }}</td>
        </tr>
      </table>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    rideTotals: Object
  }
}
</script>