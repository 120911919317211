<template>
  <div>
    <p>Logging out...</p>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/authentication';

export default {
  async created() {
        const authStore = useAuthStore()
        authStore.logout()
        this.$router.push({ name: 'Login' });
  }
}
</script>