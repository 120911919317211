<template>
  <div>
    <h1 class = "mt-4">{{selectedPlayer.player_nickname}}</h1>
    <div class="d-flex justify-content-center mt-3">
      <table>
        <tr>
          <td>
            <div>
              <ul class="list-group">
                <li
                  v-for="racer in selectedPlayer.active_racers"
                  :key="racer.id"
                  class="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex align-items-center">
                    <div class="d-flex flex-column align-items-center">
                      <img :src="racer.teamimg?racer.teamimg:'blank-profile-picture.png'" class="img-fluid" alt="Racer Image" style="width: 50px; height: 50px;">
                      <!--p>{{ r.bib }}</p-->
                      <p class="text-muted text-center" style="font-size: 13px;">#{{ racer.bib }}</p>
                    </div>
                    <div class="content-wrapper">
                      <h5 class="card-title px-2"><a :href="racer.url" target="prosite">{{ racer.name }}</a></h5>
                      <p class="card-text px-2" v-if="racer.specialties.length > 0">{{ racer.specialties.join(', ') }}</p>
                      <p class="card-text px-2" v-else>None</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div>
              <ul class="list-group">
                <li
                  v-for="racer in selectedPlayer.bench_racers"
                  :key="racer.id"
                  class="list-group-item list-group-item-secondary d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex align-items-center">
                    <div class="d-flex flex-column align-items-center">
                      <img :src="racer.teamimg?racer.teamimg:'blank-profile-picture.png'" class="img-fluid" alt="Racer Image" style="width: 50px; height: 50px;">
                      <!--p>{{ r.bib }}</p-->
                      <p class="text-muted text-center" style="font-size: 13px;">#{{ racer.bib }}</p>
                    </div>
                    <div class="content-wrapper">
                      <h5 class="card-title px-2"><a :href="racer.url" target="prosite">{{ racer.name }}</a></h5>
                      <p class="card-text px-2" v-if="racer.specialties.length > 0">{{ racer.specialties.join(', ') }}</p>
                      <p class="card-text px-2" v-else>None</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useLeagueStore } from '@/stores/league';

const leagueStore = useLeagueStore();
const selectedPlayer = computed(() => {
  const params = new URLSearchParams(window.location.search);
  const stravaid = params.get('userId');
  const player = leagueStore.players.find(p => {console.log(p.player_strava_id); return p.player_strava_id == stravaid;});
  player.active_racers=player.active_racers?player.active_racers:[]
  player.bench_racers_racers=player.bench_racers_racers?player.bench_racers_racers:[]
  return player;
})

</script>

<style scoped>
.image-parent {
  width: 50px; /* Adjust width of the image container */
  margin-right: 10px; /* Adjust margin between image and content */
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.content-wrapper {text-align: left;
}

</style>
