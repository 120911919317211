<template>
  <div class="container mt-4">
    <h1 class="mt-4">Preview</h1>
      <div class="row">
      <div v-for="league in leagueList" :key="league.id" class="card" style="width: 18rem;">
        <div class="card-body">
          <h5 class="card-title"><strong>{{ league.name }}</strong></h5>
          <table>
            <tr><td>Active Race:</td><td><a v-bind:href="league.active_race_url" target="prosite">{{ league.activeRace }}</a></td></tr>
            <tr><td>Number of Players:</td><td><p>{{ league.players.length }}</p></td></tr>
          </table>
          <div><PreviewPopup :leagueId="league.id" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useLeagueStore } from '@/stores/league';
import PreviewPopup from "@/components/LeaguePreviewPopup.vue";

const leagueStore = useLeagueStore();

const leagueList = computed(() => leagueStore.allLeagues);

onMounted(() => {
  leagueStore.getLeagueList();
});
</script>

<!--<template>
    <div>
      <h1 class = "mt-4">League Preview</h1>
        <div v-for="league in leagueList" :key="league.id" class="card" style="width: 18rem;">
          <div class="card-body">
              <h5 class="card-title">{{ league.name }}</h5>
              <table>
                <tr><td>Active Race:</td><td><a v-bind:href="league.active_race_url" target="prosite">{{ league.activeRace }}</a></td></tr>
                <tr><td>Number of Players:</td><td><p>{{ league.players.length }}</p></td></tr>
              </table>
              <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Preview</button>
               Modal
              <div><PreviewPopup :leagueId="league.id" /></div>
          </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useLeagueStore } from '@/stores/league';
import PreviewPopup from "@/components/LeaguePreviewPopup.vue";

const leagueStore = useLeagueStore()
//const league = ref(leagueStore.league);

const leagueList = computed(() => {
  return leagueStore.allLeagues;
});

//const getPlayers = leagueStore.getLeaguePlayers;
onMounted(() => {
  //getPlayers(league.value.id);
  leagueStore.getRace();
  leagueStore.getLeagueList();
});


</script>-->