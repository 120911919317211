<template>
  <div id = "app">
      <NavBar/>
      <AutoBot />
  </div>
  <router-view/>
  <div id="footer">
      <router-link to="/bypass"><img src="api_logo_cptblWith_strava_horiz_light.png" width = 10%/></router-link> 
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import AutoBot from "./components/AutoBot.vue"

export default {
  name: 'App',
  components: {
    NavBar, AutoBot
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a {
  color: #198754; /* Bootstrap success color */
}

/* Optional: Hover and active states */
a:hover,
a:focus,
a:active {
  color: #157347; /* Darker shade for hover, focus, active states */
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

