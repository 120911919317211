<template>
  <nav class="navbar navbar-expand-lg bg-dark border-bottom border-body" data-bs-theme="dark">
    <div class="container-fluid">
    <a class="navbar-brand" href="#">TourDeFantasy</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
            <router-link class="nav-link" to="/leaguehome">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/leaguepreview">League Preview</router-link>
        </li>
        <li v-if="authStore.isAuthenticated" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" @click="getMyLeaguePlayers">
            Leagues
          </a>
          <ul class="dropdown-menu">
            <li><router-link class="dropdown-item" to="/createleague">Create League</router-link> </li>
            <li><router-link class="dropdown-item" to="/joinleague">Join League</router-link></li>
            <li v-for="p in myLeaguePlayers" :key="p">
              <router-link class="dropdown-item" :to="{ path: '/joinleague', query: { leagueId: p.league_id, nickname:p.player_nickname }}" > &rarr; {{p.player_nickname}}</router-link>
            </li>
            <li><router-link class="dropdown-item" to="/livedraft">Draft Party</router-link> </li>
          </ul>
        </li>        
        <li v-if="authStore.isAuthenticated && leagueStore.leaguePlayer.active_racers" class="nav-item">
            <router-link class="nav-link" to="/roster">Roster</router-link>
        </li>
        <!--li v-if="authStore.isAuthenticated" class="nav-item">
            <router-link class="nav-link" to="/draftlist">Draft</router-link>
        </li-->
        <li v-if="authStore.isAuthenticated" class="nav-item">
            <router-link class="nav-link" to="/fullscores">Full Scores</router-link>
        </li>
        <li v-if="authStore.isAuthenticated && [13,14,15,16,17,18,19,20,21].includes(new Date().getHours())" class="nav-item">
            <router-link class="nav-link" to="/trade">Trade</router-link>
        </li>
        <li v-if="authStore.isAuthenticated && authStore.user.username!='impersonate'" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Profile
          </a>
          <ul class="dropdown-menu">
            <li><router-link class="dropdown-item" to="/profile">Info</router-link> </li>
            <li><router-link class="dropdown-item" to="/stats">Stats</router-link> </li>
            <li><router-link class="dropdown-item" to="/activities">Activities</router-link> </li>
            <li><router-link class="dropdown-item" to="/routes">Routes</router-link> </li>
          </ul>
        </li>
        <li v-if="authStore.isAuthenticated && authStore.user.roles.includes('admin')" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Admin
          </a>
          <ul class="dropdown-menu">
            <li><router-link class="dropdown-item" to="/racecenter">Race Center</router-link> </li>
            <li><router-link class="dropdown-item" to="/leaguecenter">League Center</router-link> </li>
            <li><router-link class="dropdown-item" to="/rundraft">Run Draft</router-link> </li>
            <li><router-link class="dropdown-item" to="/runtrade">Run Trade</router-link> </li>
            <li><router-link class="dropdown-item" to="/runscore">Run Score</router-link> </li>
            <li><router-link class="dropdown-item" to="/autocenter">Automation Center</router-link> </li>
            <li><router-link class="dropdown-item" to="/createleague">Create League</router-link> </li>
            <li><router-link class="dropdown-item" to="/runlivedraft">Run Draft Party</router-link> </li>
          </ul>
        </li>
        <li class="nav-item">
            <router-link class="nav-link" to="/about">Rules</router-link>
        </li>
        <li v-if="authStore.isAuthenticated" class="nav-item">
            <router-link class="nav-link" to="/logout">Logout</router-link>
        </li>
      </ul>
    </div>
  </div>
</nav>
  </template>
  
<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

import { useAuthStore } from '@/stores/authentication';
import { useLeagueStore } from '@/stores/league';

const authStore = useAuthStore();
const leagueStore = useLeagueStore();

const myLeaguePlayers = ref([]);
const getMyLeaguePlayers = async () => {
  const token = localStorage.getItem('token');
  const playerResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/league/leagueplayers`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  myLeaguePlayers.value = playerResponse.data.slice().filter( p => p.player_strava_id == authStore.user.strava_id);
};

onMounted( () => {

});
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>