<template>
  <div class="d-flex justify-content-center mt-4">
    <div v-show="tradeList.length">
      <h1>Trade Requests</h1>
        <ul class="list-group">
          <li
            v-for="request in tradeList"
            :key="request"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <div class="d-flex align-items-center flex-grow-1">
              <div class="image-parent">
                <img :src="request.toRacer.teamimg?request.toRacer.teamimg:'blank-profile-picture.png'" class="img-fluid" alt="Racer Image" style="width: 50px; height: 50px;">
              </div>
              <div class="content-wrapper">
                <a :href="request.toRacer.url" target="prosite"><strong>{{ request.toRacer.name }}</strong></a>
                <p v-if="request.toRacer.specialties.length > 0">{{ request.toRacer.specialties.join(', ') }}</p>
                <p v-else>None</p>
              </div>
            </div>
            <div class="text-end ms-3">
              <div>Requested by:</div>
                <div class="fw-bold">{{ leagueStore.getPlayerByStravaId(request.player_strava_id).player_nickname }}</div>
            </div>
            <div class="ms-5">
              <button type="button" class="btn btn-success" @click="leagueStore.cancelTradeRequest(request.id)">
                  Cancel
              </button>
            </div>
          </li>
        </ul>
    </div>
  </div>
  <div>
    <h1 class="text-center mt-4">Trade</h1>
    <div>Your current trade priority is {{ leagueStore.league.players.indexOf(leagueStore.leaguePlayer.player_strava_id) +1 }} of {{ leagueStore.league.players.length }}</div>
    <div class="container mt-3">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-6">
          <div class="text-center mb-3">
            <div><strong>{{ fromRacer.name }} >> {{ toRacer.name }}</strong></div>
          </div>
          <div class="text-center">
            <button class="btn btn-success btn-lg mb-3 me-3" @click="leagueStore.submitTrade(fromRacer, toRacer)" :disabled="!(fromRacer.name && toRacer.name)">Submit</button>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h2>Your Roster</h2>
          <ul class="list-group">
            <li
              v-for="racer in activeRacers"
              :key="racer.name"
              class="list-group-item d-flex justify-content-between align-items-center"
              :class="{ 'border-success-thick': fromRacer === racer }"
              @click="selectFromRacer(racer)"
            >
              <div class="d-flex align-items-center flex-grow-1">
                <div class="d-flex flex-column align-items-center">
                    <img :src="racer.teamimg?racer.teamimg:'blank-profile-picture.png'" class="img-fluid" alt="Racer Image" style="width: 50px; height: 50px;">
                    <!--p>{{ r.bib }}</p-->
                    <p class="text-muted text-center" style="font-size: 13px;">#{{ racer.bib }}</p>
                </div>
                <div class="content-wrapper">
                    <h5 class="card-title px-2"><a :href="racer.url" target="prosite">{{ racer.name }}</a></h5>
                    <p class="card-text px-2" v-if="racer.specialties.length > 0">{{ racer.specialties.join(', ') }}</p>
                    <p class="card-text px-2" v-else>None</p>
                </div>
              </div>
            </li>
            <li
              v-for="racer in benchRacers"
              :key="racer.name"
              class="list-group-item d-flex justify-content-between align-items-center"
              :class="{ 'border-success-thick': fromRacer === racer }"
              @click="selectFromRacer(racer)"
            >
              <div class="d-flex align-items-center flex-grow-1">
                <div class="d-flex flex-column align-items-center">
                    <img :src="racer.teamimg?racer.teamimg:'blank-profile-picture.png'" class="img-fluid" alt="Racer Image" style="width: 50px; height: 50px;">
                    <!--p>{{ r.bib }}</p-->
                    <p class="text-muted text-center" style="font-size: 13px;">#{{ racer.bib }}</p>
                </div>
                <div class="content-wrapper">
                    <h5 class="card-title px-2"><a :href="racer.url" target="prosite">{{ racer.name }}</a></h5>
                    <p class="card-text px-2" v-if="racer.specialties.length > 0">{{ racer.specialties.join(', ') }}</p>
                    <p class="card-text px-2" v-else>None</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <h2>Available Racers</h2>
          <ul class="list-group">
            <li
              v-for="racer in availableRacers"
              :key="racer.name"
              class="list-group-item d-flex justify-content-between align-items-center"
              :class="{ 'border-success-thick': toRacer === racer }"
              @click="selectToRacer(racer)"
            >
              <div class="d-flex align-items-center flex-grow-1">
                <div class="d-flex flex-column align-items-center">
                    <img :src="racer.teamimg?racer.teamimg:'blank-profile-picture.png'" class="img-fluid" alt="Racer Image" style="width: 50px; height: 50px;">
                    <!--p>{{ r.bib }}</p-->
                    <p class="text-muted text-center" style="font-size: 13px;">#{{ racer.bib }}</p>
                </div>
                <div class="content-wrapper">
                    <h5 class="card-title px-2"><a :href="racer.url" target="prosite">{{ racer.name }}</a></h5>
                    <p class="card-text px-2" v-if="racer.specialties.length > 0">{{ racer.specialties.join(', ') }}</p>
                    <p class="card-text px-2" v-else>None</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useLeagueStore } from '@/stores/league';
import { useRacerStore } from '@/stores/racer';

const leagueStore = useLeagueStore();
const league = ref(leagueStore.league);

const activeRacers = computed(() => {
  return leagueStore.leaguePlayer.active_racers;
});
const benchRacers = computed(() => {
  return leagueStore.leaguePlayer.bench_racers;
});

const racerStore = useRacerStore();
const availableRacers = computed(() => {
  return racerStore.allRacers.filter( r => !r.player)
});

const fromRacer = ref({});
const toRacer = ref({});

function selectFromRacer(racer) {
  fromRacer.value = racer;
}

function selectToRacer(racer) {
  toRacer.value = racer;
}

const tradeList = computed(() => {
  //return leagueStore.tradeList.slice().sort((a, b) => {return (a.toRacer.ranking - b.toRacer.ranking);});
  return leagueStore.tradeList.slice().filter(t => t.player_strava_id == leagueStore.leaguePlayer.player_strava_id);
});

onMounted(async () => {
  await racerStore.getRacerlist(league.value.active_race_url);
  await leagueStore.getTradeRequests();
});

</script>


<style scoped>
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}

.top-align {
  vertical-align: top;
}

.image-parent {
  width: 50px; /* Adjust width of the image container */
  margin-right: 10px; /* Adjust margin between image and content */
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.content-wrapper {text-align: left;
}

.border-success-thick {
  border: 4px solid #28a745; /* Bootstrap success green with thicker border */
}
</style>