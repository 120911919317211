<template>
  <div>
    <h1>My Activities</h1>
    <ul>
      <li v-for="activity in activities" :key="activity.id">
        {{ activity.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      activities: []
    };
  },
  async created() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ name: 'Login' });
    } else {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/activities`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.activities = response.data;
      } catch (error) {
        console.error('Error fetching activities:', error);
        this.$router.push({ name: 'Login' });
      }
    }
  }
}
</script>