<template>
  <div>
    <h1>My Stats</h1>
      <RideTotal title="All Time" :rideTotals="allTimeTotals" />
      <RideTotal title="Last 4 Weeks" :rideTotals="recentTotals" />
      <RideTotal title="This year" :rideTotals="yearTotals" />
      <table>
        <tr><td>Longest distance</td><td>{{ stats.biggest_ride_distance }}</td></tr>
        <tr><td>Most elevation</td><td>{{ stats.biggest_climb_elevation_gain }}</td></tr>
      </table>
  </div>
</template>

<script>
import RideTotal from '@/components/RideTotal.vue';
import axios from 'axios';

export default {
  data() {
    return {
      stats: {},
      allTimeTotals: {},
      recentTotals: {},
      yearTotals: {}
    };
  },
  components: {
    RideTotal
  },
  async created() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ name: 'Login' });
    } else {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/stats`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.stats = response.data;
        this.allTimeTotals = response.data.all_ride_totals
        this.recentTotals = response.data.recent_ride_totals
        this.yearTotals = response.data.ytd_ride_totals
      } catch (error) {
        console.error('Error fetching stats:', error);
        this.$router.push({ name: 'Login' });
      }
    }
  }
}
</script>