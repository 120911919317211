<template>
  <div>
    <h1>Score by Stage</h1>
    <!-- Button trigger modal -->
    <select v-model="selectedStage" @change="getScores(selectedStage)">
      <option v-for="stage in stages" :value="stage.url" :key="stage">{{stage.name}}</option>
    </select>
    <button type="button" @click="retrieveScores"> Retrieve Scores</button>

<!-- Modal -->
<h2>Racer Scores</h2>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">League Info</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <table>
        <tr><td>Id</td><td>{{league.id}}</td></tr>
        <tr><td>Name</td><td>{{ league.name }}</td></tr>
        <tr><td>Password</td><td>{{ league.password }}</td></tr>
        <tr><td>Active Race</td><td><a v-bind:href="league.active_race_url" target="prosite">{{ league.activeRace }}</a></td></tr>
      </table>
      </div>
    </div>
  </div>
</div>
<table class="table">
  <thead>
    <tr>
      <th scope="col">Cat</th>
      <th scope="col">Racer</th>
      <th scope="col">Points</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="score in stageScores" :key="score">
            <td>{{ score.category}}</td>
            <td>{{ score.racer }}</td>
            <td>{{ score.point }}</td>
    </tr>
  </tbody>
</table>
<h2>Player scores</h2><button @click="leagueStore.submitStageScore(selectedStage)">Submit</button>
<table>
        <thead>
          <tr >
              <th v-for="player in leagueStore.stagePlayers" :key="player">{{ player.player_nickname }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="player in leagueStore.stagePlayers" :key="player">
              <div v-for="racer in player.stageRacers" :key="racer" > <div><b>{{ racer.name }} </b></div>
                <div v-for="score in racer.scores" :key="score">{{ score.category }}: {{ score.point }}</div>
              </div>
              <div style="color:red">{{ player.stageScore }}</div>
              <div>{{ player.jerseys }}</div>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
</template>



<script setup>
import { computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useLeagueStore } from '@/stores/league';
import { useRacerStore } from '@/stores/racer';
import { useAutoStore } from '@/stores/automation';
import axios from 'axios';

const leagueStore = useLeagueStore()
const racerStore = useRacerStore();

const league = ref(leagueStore.league);

const getPlayers = leagueStore.getLeaguePlayers;

const stages = ref(leagueStore.race.days);
const selectedStage = ref("");
const getScores = leagueStore.getRaceScoreByStage;
const stageScores = computed(() => {
  return leagueStore.scoresForStage.slice()//.sort((a,b) => b.category-a.category);
});

const retrieveScores = async ()=> {
  const token = localStorage.getItem('token');
  const scoreResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pcs?url=${selectedStage.value}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  let doc = document.createElement('html');
  doc.innerHTML = scoreResponse.data;

  let race = league.value.active_race_url;
  let m = new Map();
  doc.querySelectorAll('.restabs li').forEach((e) => m.set(e.childNodes[0].textContent, e.childNodes[0].getAttribute("data-id")))
  if(!m.get('Stage')) m.set('Stage', m.get(''));

  const isLastStage = !Array.from(doc.querySelectorAll('.prevNextStageLink a')).slice(-1)[0].innerHTML.startsWith('next');
  //console.log(m)
  let scores = []
  // stage winners
  const stageHeaders = Array.from(doc.querySelectorAll('div[data-id="'+m.get('Stage')+'"] table.results thead th'), ({innerHTML}) => innerHTML);
  doc.querySelectorAll('div[data-id="'+m.get('Stage')+'"] table.results tbody tr').forEach((e) => {
    //console.log('html: ' + e.innerHTML)
    //if(e.children[stageHeaders.indexOf('Pnt')].textContent) {  // Pnt
    if(e.firstChild.textContent && e.children[10].textContent) {
      let s={}; 
      s.racer=e.children[stageHeaders.indexOf('Rider')].firstChild.children[1].href;  // Rider
      if (s.racer) s.racer= s.racer.replace(document.location.origin, "https://www.procyclingstats.com");
      s.race=race;
      s.category=e.firstChild.textContent;  // Rnk
      s.point=e.children[stageHeaders.indexOf('Pnt')].textContent;   // Pnt
      s.stage=selectedStage.value;
      scores.push(s); }
  });

  // gc bonus
  if(m.has('GC')){
      // yellow
    //let racer_pos = doc.querySelector('div[data-id="'+m.get('GC')+'"] table.results thead tr').childElementCount==11?4:6; // Rider
    const gcHeaders = Array.from(doc.querySelectorAll('div[data-id="'+m.get('GC')+'"] table.results thead th'), ({innerHTML}) => innerHTML);
    let s={};
    s.racer=doc.querySelector('div[data-id="'+m.get('GC')+'"] table.results tbody tr').children[gcHeaders.indexOf('Rider')].firstChild.children[1].href
    if (s.racer) s.racer= s.racer.replace(document.location.origin, "https://www.procyclingstats.com");
    s.race=race;
    s.category="yellow";
    s.point=0;
    s.stage=selectedStage.value;
    scores.push(s);

    let bonus = [20, 20, 20, 20, 20, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 10, 10, 10, 10, 10]
    doc.querySelector('div[data-id="'+m.get('GC')+'"] table.results').querySelectorAll('tbody tr').forEach((e, i) => {
      if(i<bonus.length) {
        let s={}; 
        s.racer=e.children[gcHeaders.indexOf('Rider')].firstChild.children[1].href;  // Rider
        if (s.racer) s.racer= s.racer.replace(document.location.origin, "https://www.procyclingstats.com");
        s.race=race;
        s.category="gc#" + (i+1);
        s.point=bonus[i];
        s.stage=selectedStage.value;
        scores.push(s); }
    });


  }

  // points
  if(m.has('Points')) {
    const pointsHeaders = Array.from(doc.querySelectorAll('div[data-id="'+m.get('Points')+'"] table.results thead th'), ({innerHTML}) => innerHTML);
    doc.querySelector('div[data-id="'+m.get('Points')+'"] table.results').querySelectorAll('tbody tr').forEach((e) => {
      if(e.lastChild.firstChild.textContent.indexOf('-')<0) {  // Today .delta_pnt
        let s={}; 
        s.racer=e.children[pointsHeaders.indexOf('Rider')].firstChild.children[1].href;  // Rider
        if (s.racer) s.racer= s.racer.replace(document.location.origin, "https://www.procyclingstats.com");
        s.race=race;
        s.category="sprint";
        s.point=e.lastChild.firstChild.textContent.substring(1);  // Today .delta_pnt
        s.stage=selectedStage.value;
        scores.push(s); }
      });

    // green
    let s={};  // Rider
    s.racer=doc.querySelector('div[data-id="'+m.get('Points')+'"] table.results tbody tr').children[pointsHeaders.indexOf('Rider')].firstChild.children[1].href
    if (s.racer) s.racer= s.racer.replace(document.location.origin, "https://www.procyclingstats.com");
    s.race=race;
    s.category="green";
    s.point=0;
    s.stage=selectedStage.value;
    scores.push(s);
  }

  // KOM
  if(m.has('KOM')) {
    const komHeaders = Array.from(doc.querySelectorAll('div[data-id="'+m.get('KOM')+'"] table.results thead th'), ({innerHTML}) => innerHTML);
    doc.querySelector('div[data-id="'+m.get('KOM')+'"] table.results').querySelectorAll('tbody tr').forEach((e) => {
      if(e.lastChild.firstChild.textContent.indexOf('-')<0) {
        let s={}; 
        s.racer=e.children[komHeaders.indexOf('Rider')].firstChild.children[1].href;
        if (s.racer) s.racer= s.racer.replace(document.location.origin, "https://www.procyclingstats.com");
        s.race=race;
        s.category="KOM";
        s.point=e.lastChild.firstChild.textContent.substring(1);
        s.stage=selectedStage.value;
        scores.push(s); }
        //console.log(s); }
      }); 
      
    // polkadot
    let s={};
    s.racer=doc.querySelector('div[data-id="'+m.get('KOM')+'"] table.results tbody tr').children[komHeaders.indexOf('Rider')].firstChild.children[1].href
    if (s.racer) s.racer= s.racer.replace(document.location.origin, "https://www.procyclingstats.com");
    s.race=race;
    s.category="polkadot";
    s.point=0;
    s.stage=selectedStage.value;
    scores.push(s);
  }

  // white
  if(m.has('Youth')){
    let s={};
    const youthHeaders = Array.from(doc.querySelectorAll('div[data-id="'+m.get('Youth')+'"] table.results thead th'), ({innerHTML}) => innerHTML);
    s.racer=doc.querySelector('div[data-id="'+m.get('Youth')+'"] table.results tbody tr').children[youthHeaders.indexOf('Rider')].firstChild.children[1].href
    if (s.racer) s.racer= s.racer.replace(document.location.origin, "https://www.procyclingstats.com");
    s.race=race;
    s.category="white";
    s.point=0;
    s.stage=selectedStage.value;
    scores.push(s);

    // youth bonus
    //let ybonus = [30, 20, 10]
    doc.querySelector('div[data-id="'+m.get('Youth')+'"] table.results').querySelectorAll('tbody tr').forEach((e, i) => {
      if(i<5) {
        let s={}; 
        s.racer=e.children[youthHeaders.indexOf('Rider')].firstChild.children[1].href;  //Rider
        if (s.racer) s.racer= s.racer.replace(document.location.origin, "https://www.procyclingstats.com");
        s.race=race;
        s.category="youth#" + (i+1);
        s.point=15;
        s.stage=selectedStage.value;
        scores.push(s); }
    });
  }

  if(isLastStage) {

    // last stage bonus
  }

  await axios.put(`${process.env.VUE_APP_API_BASE_URL}/racerScore/loadscores`, scores)
    .then(function (response) {
      // handle success
      console.log(response);
    })
  getScores(selectedStage.value);
};

const autoStore = useAutoStore();
const router = useRouter();

onMounted(async () => {
  await racerStore.getRacerlist(league.value.active_race_url);
  await getPlayers(league.value.id);
  await leagueStore.getRace();
  if(autoStore.isAutoMode && autoStore.currentTask) {
    console.log("auto: " + autoStore.currentTask);

    if(autoStore.currentTask.data && autoStore.currentTask.data.stageURL) {
      selectedStage.value = autoStore.currentTask.data.stageURL;
      await getScores(selectedStage.value);
      await retrieveScores();
      await leagueStore.submitStageScore(selectedStage);
      autoStore.completeTask();
      router.push('/autocenter');
    }
  } else {
    selectedStage.value = stages.value[0].url;
    getScores(selectedStage.value);
  }
  
});


</script>