<template>
  <div>
    <h1>Automation Center</h1>
    <!-- Button trigger modal -->
    <select v-model="selectedLeague" @change="getRaceDetail">
      <option v-for="l in allLeagues" :value="l" :key="l">{{l.name}}</option>
    </select>
    <button type="button" @click="autoStore.flip"> {{autoStore.isAutoMode?"Stop":"Start"}}</button>

    <div v-if="!autoStore.isAutoMode">
      <h2>{{ raceDetail.name }}</h2>
      <div>Start Date: {{ raceDetail.start_date }}</div>
      <ul>
        <li v-for="t, i in calculatedTasks" :key="i">
          <input type="checkbox" :id="i" :value="t" v-model="autoStore.tasks"/>
          <label :for="i"><b>{{ t.name }}</b> - {{ t.time.toLocaleDateString() }}</label> &nbsp;
          <input type="time" @change="updateTime($event, t)" :value="t.time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })"/>
        </li>
      </ul>
    </div>
    <div v-if="autoStore.isAutoMode">
      <ul>
        <li v-for="t, i in autoStore.tasks" :key="i">
          <input type="checkbox" :id="i" :value="t" v-model="autoStore.tasks"/>
          <label :for="i"><b>{{ t.name }}</b> - {{ t.time.toLocaleDateString() }} </label> &nbsp;
          <input type="time" @change="updateTime($event, t)" :value="t.time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })"/>
        </li>
      </ul>
    </div>
  </div>
</template>



<script setup>
import { computed, ref, onMounted } from 'vue';
import axios from 'axios';

import { useAutoStore } from '@/stores/automation';
const autoStore = useAutoStore()


const allLeagues = ref([]);
const selectedLeague = ref({});
const raceDetail = ref({});
//const timestring = ref(new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false }));
/*
const a = computed( ()=> {
  return 1;
});
*/
const getAllLeagues = async () => {
  const token = localStorage.getItem('token');
  const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/league`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  allLeagues.value = leagueResponse.data;
};

const getRaceDetail = async () => {

  const token = localStorage.getItem('token');
      const raceResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/race/url?url=${selectedLeague.value.active_race_url}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      let detail = raceResponse.data;
      detail.days.sort((a,b) => a.seq - b.seq);
      raceDetail.value = detail;
};

const calculatedTasks = computed(() => {
  if(!raceDetail.value.days) return [];
  let tasks = [{
    leagueId: selectedLeague.value.id,
    name: "Draft",
    time: new Date(Date.parse(raceDetail.value.start_date) - 60*60*1000*8),
    path: "/rundraft"
  }];
  raceDetail.value.days.forEach( (d, i) => {
    if (d.url) {
      tasks.push({
        leagueId: selectedLeague.value.id,
        name: "Score",
        time: new Date(Date.parse(raceDetail.value.start_date) + 60*60*1000*(24*i+12)),
        path: "/runscore",
        data: {stageURL: d.url}
      });}
    tasks.push({
      leagueId: selectedLeague.value.id,
      name: "Trade",
      time: new Date(Date.parse(raceDetail.value.start_date) + 60*60*1000*(24*i+19)),
      path: "/runtrade"
    });
  });
  return tasks;
});

const updateTime = (event, task) => {
  task.time.setHours(event.target.value.slice(0,2));
  task.time.setMinutes(event.target.value.slice(3));
  console.log(task.time);
};

onMounted(() => {
  getAllLeagues();
});


</script>
