<template>
  <div>
    <p>Logging in...</p>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/authentication';
import { useLeagueStore } from '@/stores/league';

export default {
  async created() {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (code) {
      try {
        const authStore = useAuthStore()
        const leagueStore = useLeagueStore()

        await authStore.login(code)
        if (authStore.user.current_league_id) {
          await leagueStore.loadLeague(authStore.user.current_league_id);
          this.$router.push({ name: 'LeagueHome'});
        } else {
          this.$router.push({ name: 'JoinLeague' });
        }
      } catch (error) {
        console.error('Error during callback:', error);
      }
    }
  }
}
</script>