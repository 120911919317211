<template>
    <h1 class = "mt-4"> Past announcements</h1>
    <div class="container w-75">
        <div v-for="a in announcements" :key="a" class="alert alert-success alert-dismissible fade show" role="alert">
          <span v-html="a.message"></span>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useLeagueStore } from '@/stores/league';
const leagueStore = useLeagueStore()

const announcements = computed(() => {
  return leagueStore.announcements.slice().reverse();
})

onMounted(() => {
  leagueStore.getAllAnnouncements();
});


</script>