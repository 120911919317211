<template>
  <div>
    <h1>Race Maintenance</h1>
    <label for="raceurl">URL:</label>
    <input type="text" size="70" id="raceurl" v-model="raceURLInput"> 
    <button type="button" @click="getRace" :disabled="!raceURLInput"> Read Race</button> 
    <button type="button" @click="saveRace" :disabled="allRaces.includes(selectedRace)"> Save Race</button> <br/>
    <select v-model="selectedRace" @change="selectRace">
      <option v-for="race in allRaces" :value="race" :key="race">{{race.name}}</option>
    </select>
    <button type="button" @click="updateRace" :disabled="!selectedRace || !allRaces.includes(selectedRace)"> Update Race Details </button> 
    <table>
      <tr><th>Race Name</th>
        <td>{{ selectedRace.name }}</td>
      </tr>
      <tr><th>Race URL</th>
        <td>{{ selectedRace.url }}</td>
      </tr>
      <tr><th>Race Start Date</th>
        <td>{{ selectedRace.start_date }}</td>
      </tr>
      <tr><th>Racers</th>
        <td>{{ selectedRace.url }}/startlist/top-competitors</td>
      </tr>     
      <tr><th>Racer Specialties</th>
        <td>{{ selectedRace.url }}/startlist/best-per-specialty</td>
      </tr>    
      <tr><th>Youth Racers</th>
        <td>{{ selectedRace.url }}/startlist/youngest-oldest</td>
      </tr>   
      <tr><th>Stages</th>
        <td>
          <div v-for="d in selectedRace.days" :key="d"> <b>{{ d.name }}</b> {{ d.url }}</div>
        </td>
      </tr>   
    </table>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
/*
import { useRacerStore } from '@/stores/racer'
const racer = useRacerStore()
*/

const allRaces = ref([]);
const selectedRace = ref({});
const raceURLInput = ref("");

const loadRaces = async () => {
  const token = localStorage.getItem('token');
  const raceResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/race`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  allRaces.value = raceResponse.data.slice().sort((a,b) => Date.parse(b.start_date)-Date.parse(a.start_date));

  //allRaces.value = raceResponse.data;
};

const getRace = async () => {
  const token = localStorage.getItem('token');
  const raceResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pcs?url=${raceURLInput.value}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  let doc = document.createElement('html');
  doc.innerHTML = raceResponse.data;
  let race = {}
  race.days = []
  race.name = doc.querySelector('div.main').textContent
  race.url = raceURLInput.value
  doc.querySelectorAll('div').forEach((element) => {if(element.textContent === 'Startdate:') {race.start_date=element.nextSibling.nextSibling.textContent}})
  let stages={}
  doc.querySelectorAll('h3').forEach((e) => {if (e.textContent==='Stages') stages = e.nextSibling.nextSibling})
  stages.querySelector('table.basic').querySelectorAll('tbody tr').forEach((e, i) => {if (e.className === 'sum') return; 
    let d={}; d.name=e.children[3].textContent; d.seq=i; d.url=e.children[3].firstChild.href; if (d.url) d.url= d.url.replace(document.location.origin, "https://www.procyclingstats.com"); 
    race.days.push(d);})
  doc.innerHTML="<html></html>"
  selectedRace.value = race;
  raceURLInput.value = "";
};

const selectRace = () => { console.log("selected")};
const saveRace = () => { 
  axios.put(`${process.env.VUE_APP_API_BASE_URL}/race/raceinfo`, selectedRace.value)
    .then(function (response) {
      // handle success
      alert(response.status);
    })
  loadRaces();
};

const updateRace = async () => { 
//{{ selectedRace.url }}/startlist/top-competitors
  const token = localStorage.getItem('token');
  const raceResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pcs?url=${selectedRace.value.url}/startlist/top-competitors`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });

  let doc = document.createElement('html');
  doc.innerHTML = raceResponse.data;

  let racers = []
  doc.querySelector('table.basic').querySelectorAll('tbody tr').forEach((e) => {let r={}; 
    r.name=e.children[1].children[1].text;
    r.ranking=e.children[0].innerText;
    r.url=e.children[1].children[1].href;
    if (r.url) r.url= r.url.replace(document.location.origin, "https://www.procyclingstats.com");
    r.nationality=e.children[1].children[0].className.split(' ')[1];
    r.race=selectedRace.value.url;
    racers.push(r);
  });
  //console.log(racers);
  doc.innerHTML="<html></html>";
  axios.put(`${process.env.VUE_APP_API_BASE_URL}/racer/racerlist`, racers)
    .then(function (response) {
      // handle success
      console.log(response);
  })
  
//{{ selectedRace.url }}/startlist/best-per-specialty
  const specialtyResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pcs?url=${selectedRace.value.url}/startlist/best-per-specialty`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  doc = document.createElement('html');
  doc.innerHTML = specialtyResponse.data;

  racers = []
  doc.querySelectorAll('table.basic').forEach((table) => {
    let specialty = table.parentElement.previousSibling.previousSibling.textContent;
    let race = selectedRace.value.url;
    table.querySelectorAll('tbody tr').forEach((e) => {
      let r={}; 
      r.racer=e.children[1].children[1].href;
      if (r.racer) r.racer= r.racer.replace(document.location.origin, "https://www.procyclingstats.com");
      r.race=race
      r.specialty=specialty
      racers.push(r);})
  })
  //console.log(racers);
  axios.put(`${process.env.VUE_APP_API_BASE_URL}/racer/racerspecialties`, racers)
    .then(function (response) {
      // handle success
      console.log(response);
    })

//{{ selectedRace.url }}/startlist/youngest-oldest
  const youthResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pcs?url=${selectedRace.value.url}/startlist/youngest-oldest`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  doc = document.createElement('html');
  doc.innerHTML = youthResponse.data;

  racers = []
  doc.querySelector('table.basic').querySelectorAll('tbody tr').forEach((e) => {let r={}; 
    r.racer=e.children[1].children[1].href;
    if (r.racer) r.racer= r.racer.replace(document.location.origin, "https://www.procyclingstats.com");
    r.race=selectedRace.value.url
    r.specialty='Youth'
    racers.push(r);
  });
  //console.log(racers);
  axios.put(`${process.env.VUE_APP_API_BASE_URL}/racer/racerspecialties`, racers)
    .then(function (response) {
      // handle success
      console.log(response);
  });

  // bibs
  const teamResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pcs?url=${selectedRace.value.url}/startlist`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  doc = document.createElement('html');
  doc.innerHTML = teamResponse.data;

  // team and bib#
  const racersteams = []
  doc.querySelectorAll("div .ridersCont").forEach((e) => {
    const team = e.querySelector("div .team").href.replace(document.location.origin, "https://www.procyclingstats.com");
    const teamname = e.querySelector("div .team").innerHTML;
    const teamimg = e.previousSibling.querySelector('img').src.replace(document.location.origin, "https://www.procyclingstats.com")
    console.log(team + " " + teamimg)
    e.querySelectorAll('li').forEach(r => {
      let rt={}; 
      rt.race = selectedRace.value.url;
      rt.bib=r.querySelector(".bib").innerHTML;
      rt.racer = r.querySelector("a").href.replace(document.location.origin, "https://www.procyclingstats.com");
      rt.team = team;
      rt.teamname = teamname;
      rt.teamimg = teamimg;
      //if (r.racer) r.racer= r.racer.replace(document.location.origin, "https://www.procyclingstats.com");
      racersteams.push(rt);
    })
  });
  console.log(racersteams);
  axios.put(`${process.env.VUE_APP_API_BASE_URL}/racer/racerteamandbib`, racersteams)
    .then(function (response) {
      // handle success
      console.log(response);
  });

  alert("updated");
};

onMounted(() => {
  loadRaces();
});
</script>