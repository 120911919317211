<template>
  <div>
    <h1>Run Trade</h1>
      <table>
        <tr><td>League Id</td><td>{{league.id}}</td></tr>
        <tr><td>League Name</td><td>{{ league.name }}</td></tr>
        <tr><td>Active Race</td><td><a v-bind:href="league.active_race_url" target="prosite">{{ league.activeRace }}</a></td></tr>
        <tr>
            <td><h2>Trade Requests</h2></td>
            <td><button @click="leagueStore.completeTradeRequests" > Finalize Trade </button></td>
        </tr>
        <tr>
          <td><div v-for="request in tradeList" :key="request" :class="request.selected ? 'own' : ''">
            <span>{{ request.toRacer.name }} [{{ request.toRacer.ranking }}]</span> <span> >> </span> 
            <span> {{ request.fromRacer.name }} [{{ request.fromRacer.ranking }}]</span> by 
            <span> {{ leagueStore.getPlayerByStravaId(request.player_strava_id).player_nickname }}</span>
            <span> [{{ leagueStore.getPlayerPositionByStravaId(request.player_strava_id) }}]</span>
          </div></td>
        </tr>
      </table>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useLeagueStore } from '@/stores/league';
import { useAutoStore } from '@/stores/automation';

const leagueStore = useLeagueStore();
const league = ref(leagueStore.league);


const tradeList = computed(() => {
  return leagueStore.tradeList
});

const autoStore = useAutoStore();

const router = useRouter();

onMounted(async () => {
  //await leagueStore.getTradeRequests();
  await leagueStore.getMultiTradeRequests();

  if(autoStore.isAutoMode && autoStore.currentTask) {
    if(leagueStore.tradeList.length >0) await leagueStore.completeTradeRequests();
    autoStore.completeTask();
    router.push('/autocenter');
  }
});
</script>

<style scoped>
.own {
  background-color: #eee;
}
</style>