<template>
  <div>
    <h1 class = "mt-4">Draft List</h1> {{ dragTarget }}
    <div class="d-flex justify-content-center mt-3">
      <table>
        <tr>
          <td>
            <button class="btn btn-success btn-lg mb-3 me-3" @click="submit">Save</button>
          </td>
        </tr>
        <tr>
          <td>
            <div @drop="onDrop($event)" class="drop-zone" @dragover.prevent>
              <ul class="list-group">
                <li
                  v-for=" (racer, idx) in racers" 
                  :key="racer" 
                  class="list-group-item d-flex justify-content-between align-items-center"
                  draggable="true" 
                  @dragstart="startDragging($event, racer)" 
                  @dragenter="dragEnter($event, racer)"
                >
                <div class="d-flex align-items-center flex-grow-1">
                    <div class="image-parent">
                      <img src="blank-profile-picture.png" class="img-fluid" alt="Racer Image">
                    </div>
                    <div class="content-wrapper">
                      <a :href="racer.url" target="prosite"><strong>{{ racer.name }}</strong></a>
                      <p v-if="racer.specialties.length > 0"> {{ racer.specialties.join(', ') }}</p>
                      <p v-else> None</p>
                    </div>
                </div>
                    <div class="btn-group ms-2" role="group" aria-label="Basic example">
                      <div class="border-end">
                      <button @click="racerStore.move(idx, 'up')" type="button" class="btn btn-success" :disabled="idx === 0">↑</button>
                    </div>
                    <div>
                      <button @click="racerStore.move(idx, 'down')" type="button" class="btn btn-success" :disabled="idx === racers.length - 1">↓</button>
                    </div>
                    <div>
                      <button @click="racerStore.move(idx, 'top')" type="button" class="btn btn-success" :disabled="idx === 0">top</button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useLeagueStore } from '@/stores/league';
import { useRacerStore } from '@/stores/racer';

const leagueStore = useLeagueStore()
const racerStore = useRacerStore()

const league = ref(leagueStore.league);
const racers = computed(() => {
  return racerStore.racers;
});
//const racers = ref(racerStore.racers);

const getRacers = racerStore.loadDraftlist;

const dragTarget = ref("");

function dragEnter(event, racer) {
  dragTarget.value = racer.name
}

function startDragging(event, racer) {
  console.log("start dragging " + racer.name)
  event.dataTransfer.dropEffect = 'move'
  event.dataTransfer.effectAllowed = 'move'
  event.dataTransfer.setData('racerName', racer.name)
}

function onDrop(event) {
  let racername = event.dataTransfer.getData('racerName')
  //console.log(event.dataTransfer.getData('racerName') + " insert before " + this.dragTarget)
  if(dragTarget.value != racername) {
    racerStore.insertBefore(racername, dragTarget.value)
  }
  dragTarget.value = ""
  event.preventDefault();
}

function submit() {
  racerStore.updateDraftlist(league.value.active_race_url);
}

onMounted(() => {
  getRacers(league.value.active_race_url);
});
</script>


<style scoped>
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}

.image-parent {
  width: 50px; /* Adjust width of the image container */
  margin-right: 10px; /* Adjust margin between image and content */
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.content-wrapper {text-align: left;
}

</style>