<template>
  <div><h1 class = "mt-4">Scores</h1>
    <div v-for="stage in playerScores.stage_scores" :key="stage">
      <h2><a v-bind:href="stage.stage_url" target="prosite">{{stageNameMaps.get(stage.stage_url)[0].name}}</a></h2>
      <table class="table">
        <thead>
              <tr>
                <th scope="col">Racer</th>
                <th scope="col">Points Earned</th>
                <th scope="col">How Earned</th>
              </tr>
            </thead>
        <tbody>
          <tr v-for="(racer) in stage.racers" :key="racer.url">
            <td><b>{{ racer.name }}</b>
              <span v-if="scoresMap.get(stage.stage_url)">
                <span v-for="s in scoresMap.get(stage.stage_url).get(racer.url)" :key="s">
                  <img v-if="['white', 'yellow', 'polkadot', 'green', '1', '2', '3', 'youth#1', 'youth#2', 'youth#3', 'gc#1', 'gc#2', 'gc#3'].includes(s.category)" 
                       :src="'/' + s.category.replace('#', '_') + '.png'" style="width: 20px; height: 20px;" />
                </span>
              </span>
            </td>
            <td>
              <div v-if="scoresMap.get(stage.stage_url)">
                <div v-for="s in scoresMap.get(stage.stage_url).get(racer.url)" :key="s">
                  <span v-if="!['white', 'yellow', 'green', 'polkadot'].includes(s.category)">{{ s.point }}</span>
                  <span v-else-if="s.point !== 0">{{ s.point }}</span>
                </div>
              </div>
            </td>
            <td>
              <div v-if="scoresMap.get(stage.stage_url)">
                <div v-for="s in scoresMap.get(stage.stage_url).get(racer.url)" :key="s">
                  <div v-if="!['white', 'yellow', 'polkadot', 'green'].includes(s.category)">
                      <div v-if= "s.category.includes('#')">
                        <span>
                          {{ s.category.split('#')[0] }} {{ isNaN(s.category.split('#')[1]) ? s.category.split('#')[1] : s.category.split('#')[1] + (s.category.split('#')[1] % 10 == 1 && s.category.split('#')[1] % 100 != 11 ? 'st' : s.category.split('#')[1] % 10 == 2 && s.category.split('#')[1] % 100 != 12 ? 'nd' : s.category.split('#')[1] % 10 == 3 && s.category.split('#')[1] % 100 != 13 ? 'rd' : 'th') }}
                        </span>
                      </div>
                      <div v-else>
                      <span>
                        {{ isNaN(s.category) ? s.category : s.category + (s.category % 10 == 1 && s.category % 100 != 11 ? 'st' : s.category % 10 == 2 && s.category % 100 != 12 ? 'nd' : s.category % 10 == 3 && s.category % 100 != 13 ? 'rd' : 'th') }}
                      </span>
                      </div>
                    </div>
                </div>
              </div>
            </td>
          </tr>
          <tr class="border-bottom border-2">
                <th scope="row">Total</th>
                <td>{{ stage.total_score }}</td>
                <td></td>
              </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>



<script setup>
import { computed, ref, onMounted } from 'vue';
import { useLeagueStore } from '@/stores/league';
import axios from 'axios';

const leagueStore = useLeagueStore()

const league = ref(leagueStore.league);
/*
const players = computed(() => {

  let copy = leagueStore.players.slice();
  copy.sort((a,b) => {return b.current_score - a.current_score});

  //console.log(JSON.stringify(copy));
  return copy//leagueStore.players//.slice().sort((a,b) => a.current_score - b.current_score);
});
*/
const playerScores = computed( () => {
  const params = new URLSearchParams(window.location.search);
  const stravaid = params.get('userId');
  const player = leagueStore.players.find(p => {console.log(p.player_strava_id); return p.player_strava_id == stravaid;});
  //player.stage_scores.reverse();
  return player;
});

const stageScores = ref([]);
const scoresMap = ref(new Map());
const loadStageScores = async () => {

  const token = localStorage.getItem('token');
  const leagueResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/racerScore/search?race=${league.value.active_race_url}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  stageScores.value = leagueResponse.data;
  //console.log(stageScores.value);

  let map = Map.groupBy(stageScores.value, ({stage}) => stage);
  map.forEach( (v, stageurl, m) => {
    m.set(stageurl, Map.groupBy(v, ({racer}) => racer));
  })

  scoresMap.value = map;
  //console.log(map);
};

const stageNameMaps = computed(() => {
  return Map.groupBy(leagueStore.race.days, ({url}) => url);
});

const getPlayers = leagueStore.getLeaguePlayers;
onMounted(async () => {
  await getPlayers(league.value.id);
  await leagueStore.getRace();
  await loadStageScores();
});


</script>